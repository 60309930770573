import React, {useState, useEffect} from 'react'
import { getTeacherTickets } from '../LocalAPICalls'
import { format } from 'date-fns'
import TeacherTicketItem from './TeacherTicketItem'

const TeacherTicketList = ({ instance, accounts, loginRequest, teacher, menu, noTitle }) => {
  const [title, setTitle] = useState('')
  const [tickets, setTickets] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    switch(menu) {
      case 'A':
        setTitle('Academic Ticket List')
        break
      case 'B':
        setTitle('Behaviour Ticket List')
        break
      case 'T':
        setTitle('Previous Tracks')
        break
      case 'C':
        setTitle('Commendations')
        break
      default:
        setTitle('Uniform Ticket List')
        break
    }
    // TODO: Make this teacher focussed
    // Get Tickets from API
    getTeacherTickets(teacher, menu)
    .then((response) => {
      setTickets(response)
    })
    .catch((err) => {
      setError(true)
      console.log(err.message)
    })
    .finally(
      setLoading(false)
    )
  }, [menu, teacher])

  const deleteTicket = (id) => {
    setTickets(tickets.filter((ticket) =>
    ticket.id !== id))
  }

  return (
    <div>
      { loading && <div>A moment please...</div> }
      { error && (
        <div
          style={{textAlign: 'center'}}
        >
          There was a problem getting the tickets
        </div>
      )}

      { !noTitle &&
        <h3>{ title }</h3>
      }
      { tickets.map((item) => (
            <TeacherTicketItem
              key={ item.id }
              id={ item.id }
              instance={ instance }
              accounts={ accounts }
              loginRequest={ loginRequest }
              reason={ item.reason }
              student={ item.student }
              notes={ item.notes }
              date={ format(new Date(item.date || item.timeOut), 'dd/MM/yyyy h:mma') }
              timeIn={ item.timeIn ? format(new Date(item.timeIn), 'dd/MM/yyyy h:mma') : 'Still out of class' }
              menu={ menu }
              onDelete={ deleteTicket }
            />
      )) }
    </div>
  )
}

export default TeacherTicketList