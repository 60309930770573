import React from 'react'
import Button from './Button'
import { Link } from 'react-router-dom'

const Menu = () => {
	return (
		<div className='menu'>
			<h3>Main Menu</h3>
			
			<Link className='text-link' to='/tracker'>
				<Button title='Tracker' />
			</Link>

			<Link className='text-link' to='/uniform'>
				<Button title='Uniform' />
			</Link>

			<Link className='text-link' to='/behaviour'>
				<Button title='Behaviour' />
			</Link>

			<Link className='text-link' to='/academic'>
				<Button title='Academic' />
			</Link>

			<Link className='text-link' to='/commendations'>
				<Button title='The LORDS Way' />
			</Link>
		</div>
	)
}

export default Menu
