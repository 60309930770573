import React, { useEffect, useState } from 'react'
import TeacherLeaderboardItem from './TeacherLeaderboardItem'
import Select from 'react-select'
import { getWeeks } from '../LocalAPICalls'

const TeacherLeaderboard = ({
	instance,
	accounts,
	loginRequest,
	leaderBoard,
	setWhich,
	setWeek,
}) => {
	const [register, setRegister] = useState({
		value: 'C',
		label: 'Commendations',
	})
	const [theWeek, setTheWeek] = useState({ value: 11, label: 'Whole Term' })
	const [weeks, setWeeks] = useState([])

	useEffect(() => {
		getWeeks().then((response) => {
			let theList = response
			theList.unshift({ value: '11', label: 'Whole Term' })
			setWeeks(theList)
		})
	}, [])

	const registers = [
		{
			value: 'C',
			label: 'Commendations',
		},
		{
			value: 'A',
			label: 'Academic Register',
		},
		{
			value: 'B',
			label: 'Behaviour Register',
		},
		{
			value: 'T',
			label: 'Tracker',
		},
		{
			value: 'U',
			label: 'Uniform Register',
		},		
	]

	let place = 1

	const handleWeekChange = (e) => {
		setTheWeek(e)
		setWeek(e.value)
	}

	const handleWhichChange = (e) => {
		setRegister(e)
		setWhich(e.value)
	}

	return (
		<>
			{leaderBoard && (
				<>
					<div className='form-control'>
						<label>Register:</label>

						<Select
							placeholder='Choose a register...'
							value={register}
							options={registers}
							onChange={handleWhichChange}
							autoFocus
						/>
					</div>

					<div className='form-control'>
						<label>Week:</label>

						<Select
							placeholder='Choose a week...'
							value={theWeek}
							options={weeks}
							onChange={handleWeekChange}
						/>
					</div>

					{leaderBoard.map((item) => (
						<TeacherLeaderboardItem
							key={item.teacher}
							instance={instance}
							accounts={accounts}
							loginRequest={loginRequest}
							teacher={item.teacher}
							which={register.value}
							count={item.count}
							place={place++}
						/>
					))}
				</>
			)}
		</>
	)
}

export default TeacherLeaderboard
