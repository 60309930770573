import React from 'react'
import Button from './Button'
import Search from './Search'
import { Link } from 'react-router-dom'

const Academic = ({ instance, accounts, loginRequest, teacher, admin }) => {
  return (
    <>
        <h3>Academic Register</h3>
        <Link className='text-link' to='/'><Button title='Main Menu' /></Link>
        <Search
          instance={ instance }
          accounts={ accounts }
          loginRequest={ loginRequest }
          teacher={ teacher }
          menu='A'
          admin={ admin }
        />
    </>
  )
}

export default Academic