import React from 'react'
import Button from './Button'
import { Link } from 'react-router-dom'

const Test = () => {
    const encTest = () => {
        console.log('Student Regsiter: Encryption Test')
    }

    return (
        <>
            <h3>Dev Tests</h3>

            <Link className='text-link' to='/'>
                <Button title='Main Menu' />
            </Link>

            <Link className='text-link' to='/admin'>
                <Button title='Admin' />
            </Link>

            <br />

            <Button title='Encryption Test' onClick={encTest} />
        </>
    )
}

export default Test