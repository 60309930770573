import React, { useEffect, useState } from 'react'
import Button from './Button'
import { Link } from 'react-router-dom'
import { getStaffLeaderboard } from '../LocalAPICalls'
import TeacherLeaderboard from './TeacherLeaderboard'

const StaffLeaderboard = ({ instance, accounts, loginRequest, admin }) => {
    const [leaderBoard, setLeaderBoard] = useState([])
    const [which, setWhich] = useState('C')
    const [week, setWeek] = useState('11')

    useEffect(() => {
        getStaffLeaderboard(which, week)
            .then((response) => {
                setLeaderBoard(response)
            })
    }, [which, week])

    const getLeaderBoardTitle = () => {
        switch(which) {
            case 'A':
                return 'Academic Register'
            case 'B':
                return 'Behaviour Register'
            case 'T':
                return 'Tracks'
            case 'C':
                return 'Commendations'
            default:
                return 'Uniform Register'
        }
    }

    return (
        <>
        { admin ?
            <>
                <h3>Staff Leaderboards</h3>
                <Link to='/' className='text-link'><Button title='Main Menu' /></Link>
                <Link to='/Admin' className='text-link'><Button title='Admin' /></Link>
            </>
            :
            <>
                <p>Sorry, you are not authorised to view this page.</p>
                <Link to='/' className='text-link'><Button title='Main Menu' /></Link>
            </>
        }

        { leaderBoard &&
            <TeacherLeaderboard
            instance={ instance }
            accounts={ accounts }
            loginRequest={ loginRequest }
            leaderBoard={ leaderBoard }
            title={ getLeaderBoardTitle() }
            setWhich={ setWhich }
            setWeek={ setWeek }
            />
        }
        </>
    )
}

export default StaffLeaderboard