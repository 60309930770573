import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Button from './Button'
import Select from 'react-select'
import { callMSGraphAll } from '../graph'
import { utils, writeFileXLSX } from 'xlsx'
import { format } from 'date-fns'
import { getMenuItems, getRegData } from '../LocalAPICalls'

const RegReport = ({ instance, accounts, loginRequest }) => {
  const [studentList, setStudentList] = useState([])
  const [register, setRegister] = useState({
			value: 'C',
			label: 'Commendations',
	})
  const [reasons, setReasons] = useState([])
  const [yrLevel, setYrLevel] = useState({
      value: '0',
      label: 'All Year Levels'
  })

  const yrLevels = [
    {
      value: '0',
      label: 'All Year Levels'
    },
    {
      value: '7',
      label: 'Year 7'
    },
    {
      value: '8',
      label: 'Year 8'
    },
    {
      value: '9',
      label: 'Year 9'
    },
    {
      value: '10',
      label: 'Year 10'
    },
    {
      value: '11',
      label: 'Year 11'
    },
    {
      value: '12',
      label: 'Year 12'
    }
  ]

  const registers = [
    {
			value: 'C',
			label: 'Commendations',
		},
		{
			value: 'A',
			label: 'Academic Register',
		},
		{
			value: 'B',
			label: 'Behaviour Register',
		},
		{
			value: 'T',
			label: 'Tracker',
		},
		{
			value: 'U',
			label: 'Uniform Register',
		},
	]

  useEffect(() => {
    const request = {
        ...loginRequest,
        account: accounts[0]
    }
          
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request)
      .then((response) => {
          callMSGraphAll(response.accessToken)
            .then((response) => {
              setStudentList(
                response.value
              )
            })
            return
      })
      .catch((e) => {
          console.log(e.message)
          instance.acquireTokenPopup(request)
            .then((response) => {
              callMSGraphAll(response.accessToken)
                .then((response) => {
                  setStudentList(
                    response.value
                  )
                })
                return
            })
      })

      getMenuItems(register.value)
        .then((res) => {
          setReasons(res)
        })
  }, [instance, accounts, loginRequest, register])

  const writeList = (rList) => {
    const ws = utils.json_to_sheet(rList)
    const wb = utils.book_new()
    utils.book_append_sheet(wb, ws, `${register.label} Data`)
    const today = format(new Date(), 'yyyy-MM-dd')
    writeFileXLSX(wb, `${register.label}, ${yrLevel.label} as at ${today}.xlsx`)
  }

  const getReasons = (reason) => {
    let reasonString = ''

    reasons.map((rCode) => {
      if(rCode.id & reason) {
        if(register.value === 'C') {
          reasonString += `${rCode.category}: I can be ${rCode.name}, `
        } else {
          reasonString += `${rCode.name}, `
        }
      }

      return ''
    })
    
    return reasonString.slice(0, -2)
  }

  const generate = async() => {
    let regList = await getRegData(register.value)

    regList = regList.map((student) => {
      const thisStudent = studentList.find((el) => el.userPrincipalName === `${student.student}@sol.qld.edu.au`)
      return {
        ...student,
        ...thisStudent
      }
    })

    regList = regList.map((student) => {
      let theDate = ''

      if(register.value === 'T') {
        theDate = format(new Date(student.timeOut), 'dd/MM/yyyy')
      } else {
        theDate = format(new Date(student.date), 'dd/MM/yyyy')
      }

      return ({
        id: student.student,
        name: `${student.givenName} ${student.surname}`,
        pc: student.officeLocation,
        date: theDate,
        reason: getReasons(student.reason),
        teacher: student.teacher,
        notes: student.notes
      })
    })
    
    if(yrLevel.value !== '0') {
      regList = regList.filter((student) => {
        if(student.pc) {
          return yrLevel.value === student.pc.slice(0, -1)
        } else {
          return {}
        }
      })
    }

    writeList(regList)
  }

  const handleRegChange = async(e) => {
    setRegister(e)

    const rCodes = await getMenuItems(e.value)
    console.log(rCodes)
    setReasons(rCodes)
  }

  const handleYrLevelChange = (e) => {
    setYrLevel(e)
  }

  return (
    <>
      <h3>Register Reports</h3>

      <Link className='text-link' to='/'>
        <Button title='Main Menu' />
      </Link>

      <Link className='text-link' to='/admin'>
        <Button title='Admin' />
      </Link>

      <div className='form-control'>
				<label>Register:
          <Select
            placeholder='Choose a register...'
            value={register}
            options={registers}
            onChange={handleRegChange}
            autoFocus
          />
        </label>
      </div>

      <div className='form-control'>
				<label>Year Level:
          <Select
            placeholder='Choose a week...'
            value={yrLevel}
            options={yrLevels}
            onChange={handleYrLevelChange}
          />
        </label>

      </div>

      <Button title='Generate Report' onClick={generate} />
      <br /><br /><br /><br /><br /><br />
    </>
  )
}

export default RegReport