import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getTicketCount, getTicketColor } from '../LocalAPICalls'
import Image from './Image'
// import Stack from './Stack'

const DisplayItem = ({ student, name, pc, menu, admin, pcTeachers }) => {
	const [count, setCount] = useState(0)
	const [error, setError] = useState(false)
	const [pct, setPCT] = useState('none')
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const list = pcTeachers.reduce((acc, cur) => {
			if(cur.officeLocation === pc) {
				return `${acc}|${cur.userPrincipalName}`
			} else {
				return acc
			}
		}, '')

		if(list !== '') {
			setPCT(list)
		}

		getTicketCount(student, menu)
			.then((response) => {
				setCount(response[0].count)
			})
			.catch((err) => {
				console.log(err.message)
				setError(true)
			})
			.finally(setLoading(false))
	}, [menu, student, pc, pcTeachers])

	return (
		<>
			{loading && <div>A moment please...</div>}
			{error && (
				<div style={{ textAlign: 'center' }}>
					There was a problem getting the tickets
				</div>
			)}
			<div className='student-list'>
				<Link
					className='student-list-link text-link'
					to={
						`/Student/${student}/${name}/${pc}/${menu}/none/${pct}`
					}
				>
					<div
						className='student-panel'
						style={{
							borderLeft: '6px solid ' + getTicketColor(count),
						}}
					>
						<div>{name + ' (' + pc + ')'}</div>

						<div style={{display: 'flex'}}>
							<Image id={student} name={name} size='30px' center={false} />
							{/* <span
								style={{fontSize: '3rem', color: 'maroon'}}
							>+</span> */}
						</div>
					</div>
				</Link>
			</div>
		</>
	)
}

export default DisplayItem
