import React, { useEffect } from 'react'
import { getTermCount, getTicketColor } from '../LocalAPICalls';

const TrafficLights = ({student, menu, count, term}) => {

    useEffect(() => {
        const getLightColor = (t, thisTerm) => {
            getTermCount(t, student, menu)
                    .then((response) =>
                        thisTerm.style.backgroundColor = getTicketColor((response[0].count)
                    ))
        }

        let thisTerm = document.querySelector('#t' + term)
        thisTerm.style.backgroundColor = getTicketColor(count)

        for(let t = 1; t < term; t++) {
            thisTerm = document.querySelector('#t' + t)
            getLightColor(t, thisTerm)
        }
    }, [count, menu, student, term])

  return (
    <div className='traffic-lights'>
        <div className='light' id='t1' title='Term 1'>1</div>
        <div className='light' id='t2' title='Term 2'>2</div>
        <div className='light' id='t3' title='Term 3'>3</div>
        <div className='light' id='t4' title='Term 4'>4</div>
    </div>
  )
}

export default TrafficLights