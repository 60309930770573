import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { getMenuItems, getPeriod } from '../LocalAPICalls'
import { callMsGraphStaff } from '../graph'
import Image from './Image'

const TrackCard = ({ loginRequest, accounts, instance, student, studentName, teacher, timeOut, reason, notes, id, onDelete, onClick, pc }) => {
    const [teacherName, setTeacherName] = useState('')
    const [reasons, setReasons] = useState('')
    const [period, setPeriod] = useState(0)

    useEffect(() => {
        const getTeacherName = () => {
            const searchString = teacher
            
            const request = {
                ...loginRequest,
                account: accounts[0]
            }
            
            // Silently acquires an access token which is then attached to a request for Microsoft Graph data
            instance.acquireTokenSilent(request)
                .then((response) => {
                    callMsGraphStaff(searchString, response.accessToken)
                        .then((response) => {
                            setTeacherName(response.value[0].displayName)
                        })
                })
                .catch((e) => {
                    console.log(e.message)
                    instance.acquireTokenPopup(request)
                        .then((response) => {
                            callMsGraphStaff(searchString, response.accessToken)
                                .then((response) => {
                                    setTeacherName(response.value[0].displayName)
                                })
                            return
                        })
                })
        }

        getTeacherName()

        getPeriod(timeOut)
            .then((response => {
                setPeriod(response.period)
            }))
    }, [accounts, instance, loginRequest, teacher, timeOut])

    const getReasons = () => {
        getMenuItems('T')
            .then((response) => {
                let count = reason
                let reasonString = ''

                response.map((item) => {
                    if(item.id & reason) {
                        reasonString += item.name
                        
                        count -= item.id
                
                        if(count > 0) {
                          reasonString += ', '
                        }
                      }

                    setReasons(reasonString)
                    return ''
                })
            })
        }

    return (
        <div
            id={ id }
            className='student-card'
            onClick={ onClick }
        >
            <div
                className='return-student'
                onClick={ onDelete }
            ></div>

            <div>
                <strong>{ studentName } ({ pc })</strong><br />
                <strong>Teacher: </strong>{ teacherName }<br />
                <strong>Period: </strong>{ period }<br />
                <strong>Time Out: </strong>{ format(new Date(timeOut), 'dd/MM/yyyy h:mma') }<br />
                { getReasons() }
                <strong>Reasons: </strong>{ reasons }<br />
                { notes && 
                    <>
                        <strong>Notes: </strong>{ notes }
                    </>
                }
            </div>
            <Image
                id={ student }
                name={ studentName }
                size='60px'
                center={ false }
            />
        </div>
    )
}

export default TrackCard