import React from 'react'

const Footer = ({ version, isDev }) => {

  return (
    <footer>
      &copy; 2023 LORDS Technology (v {version})
      {isDev === 'DEV' && <p className='marquee'>Development Server!</p>}
    </footer>
  )
}

export default Footer