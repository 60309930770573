import React from 'react'
import { Link } from 'react-router-dom'
import Button from './Button'

const PopUp = ({ whereFrom, theClass }) => {
	let locale = ''
	let title = ''

	window.scrollTo(0, 0)

	switch (whereFrom) {
		case 'A':
			locale = '/Academic'
			title = 'Academic Register'
			break
		case 'B':
			locale = '/Behaviour'
			title = 'Behaviour Register'
			break
		case 'T':
			locale = '/Tracker'
			title = 'Student Tracker'
			break
		case 'C':
			locale = '/Commendations'
			title = 'Commendations'
			break
		default:
			locale = '/Uniform'
			title = 'Uniform Register'
			break
	}

	return (
		<div id='popUp' className='popUp'>
			<center>
				<p style={{ fontSize: '1.2rem' }}>Your ticket has been submitted.</p>
			</center>
			<Link className='text-link' to={locale}>
				<Button title={title} />
			</Link>
			{theClass && theClass !== 'none' && (
				<Link className='text-link' to={'/MyClasses/' + theClass}>
					<Button title='Back to my class' />
				</Link>
			)}
			<Link className='text-link' to='/'>
				<Button title='main menu' />
			</Link>
		</div>
	)
}

export default PopUp
