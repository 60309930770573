import React, { useEffect, useState } from 'react'
import { getTrackerList } from '../LocalAPICalls'
import TrackItem from './TrackItem'

const TrackerList = ({ instance, accounts, loginRequest, user, admin }) => {
  const [tracks, setTracks] = useState([])
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getTrackerList()
      .then((response) => {
        setTracks(response)
      })
      .catch((err) => {
        console.log(err.message)
        setError(true)
      })
      .finally(
        setLoading(false)
      )
  },[])

  const delTrack = (item) => {
    setTracks(tracks.filter((track) =>
      track.id !== item
    ))
  }

  return (
    <>  
      { loading && <div>A moment please...</div> }
      { error && <div>There was a problem getting the tickets</div>}

      {tracks &&
        tracks.map((track) => (
          <TrackItem
            key={ track.id }
            id={ track.id }
            student={ track.student }
            teacher={ track.teacher }
            user={ user }
            timeOut={ track.timeOut }
            timeIn={ track.timeIn }
            reason={ track.reason }
            notes={ track.notes }
            instance={ instance }
            accounts={ accounts }
            loginRequest={ loginRequest }
            trackList={ tracks }
            onDelete={ delTrack }
            admin={ admin }
          />
        ))
      }
    </>
  )
}

export default TrackerList