import React from 'react'
import { Link } from 'react-router-dom'
import Button from './Button'

const My404 = () => {
  return (
    <>
        <h3>Page Not Found</h3>
        <h4>Sorry. This page does not exist</h4>
        <br />

        <Link className='text-link' to='/'>
            <Button title='Main Menu' />
        </Link>
    </>
  )
}

export default My404