import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
//import Button from './Button'
import Image from './Image'
import { getTicketCount, getTicketColor } from '../LocalAPICalls'
import { callMsGraphByID } from '../graph'

const ClassListItem = ({
	instance,
	accounts,
	loginRequest,
	student,
	menu,
	theClass,
}) => {
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(true)
	const [name, setName] = useState('')
	const [pc, setPC] = useState('')
	const [count, setCount] = useState(0)
	const [show, setShow] = useState(true)

	useEffect(() => {
		const request = {
			...loginRequest,
			account: accounts[0],
		}

		// Silently acquires an access token which is then attached to a request for Microsoft Graph data
		instance
			.acquireTokenSilent(request)
			.then((response) => {
				callMsGraphByID(student, response.accessToken).then((response) => {
					if (response.value[0]) {
						setName(response.value[0].displayName)
						setPC(response.value[0].officeLocation)
					} else {
						setShow(false)
					}
				})
			})
			.catch((e) => {
				console.log(e.message)
				instance.acquireTokenPopup(request).then((response) => {
					callMsGraphByID(student, response.accessToken).then((response) => {
						setName(response.value[0].displayName)
						setPC(response.value[0].officeLocation)
					})
				})
			})
		if (show) {
			getTicketCount(student, menu)
				.then((response) => {
					setCount(response[0].count)
					setError(false)
				})
				.catch((err) => {
					console.log(err.message)
					setError(true)
				})
				.finally(() => {
					setLoading(false)
					setError(false)
				})
		}
	}, [student, accounts, instance, loginRequest, menu, show])

	return (
		<>
			{loading && <div>A moment please...</div>}
			{error && (
				<div style={{ textAlign: 'center' }}>
					There was a problem getting student details
				</div>
			)}
			{show && (
				<div className='student-list'>
					<Link
						className='student-list-link text-link'
						to={
							'/Student/' +
							student +
							'/' +
							name +
							'/' +
							pc +
							'/' +
							menu +
							'/' +
							theClass
						}
					>
						<div
							className='student-panel'
							style={{ borderLeft: '6px solid ' + getTicketColor(count) }}
						>
							{name + ' (' + pc + ')'}
							<Image id={student} name={name} size='40px' center={false} />
						</div>
					</Link>
				</div>
			)}
		</>
	)
}

export default ClassListItem
