import React, { useState, useEffect } from 'react'
import { callMsGraph, callMsGraphStaffPC } from '../graph'
import Display from './Display'

const Search = ({ instance, accounts, loginRequest, teacher, menu, admin }) => {
    const [graphData, setGraphData] = useState([])
    const [pcTeachers, setPCTeachers] = useState([])

    useEffect(() => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        }

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            callMsGraphStaffPC(response.accessToken)
                .then(response =>
                    setPCTeachers(response.value)
                )
        }).catch((e) => {
            instance.acquireTokenPopup(request)
                .then((response) => {
                    callMsGraphStaffPC(response.accessToken)
                        .then(response => 
                            setPCTeachers(response.value)
                        )
                })
        })
    }, [accounts, instance, loginRequest])

    function RequestProfileData() {
        const searchString = document.getElementById('searchString').value
        if(searchString !== '') {
            if(searchString && searchString.length >= 1) {
                const request = {
                    ...loginRequest,
                    account: accounts[0]
                }

                // Silently acquires an access token which is then attached to a request for Microsoft Graph data
                instance.acquireTokenSilent(request).then((response) => {
                    callMsGraph(searchString, response.accessToken)
                        .then(response =>
                            setGraphData(response)
                        )
                }).catch((e) => {
                    instance.acquireTokenPopup(request)
                        .then((response) => {
                            callMsGraph(searchString, response.accessToken)
                                .then(response => 
                                    setGraphData(response)
                                )
                        })
                })
            }
        } else {
            setGraphData([])
        }
    }

    return (
        <div className='form-control'>
            <label>Search:</label>
            <input
                id='searchString'
                placeholder='Type in a name or PC group...'
                onKeyUp={ () => RequestProfileData() }
                autoFocus={ true }
            />
            { graphData.value &&
                <Display
                    instance={ instance }
                    accounts={ accounts }
                    loginRequest={ loginRequest }
                    graphData={ graphData }
                    teacher={ teacher }
                    menu={ menu }
                    admin={ admin }
                    pcTeachers = { pcTeachers }
                />
            }
        </div>
  )
}

export default Search