import React, { useEffect, useState } from 'react'
import { deleteTicket, getMenuItems } from '../LocalAPICalls'
import { callMsGraphStaff } from '../graph'
import Track from './images/track.png'

const TicketItem = ({ instance, accounts, loginRequest, reason, teacher, notes, date, timeIn, menu, id, onDelete, admin }) => {
  const [reasons, setReasons] = useState([])
  const [teacherName, setTeacherName] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  const thisTeacher = accounts[0].username.split('@')[0].toLowerCase()

  useEffect(() => {
    getMenuItems(menu)
      .then((response) => {
        setReasons(response)
      })
      .catch((err) => {
        console.log(err.message)
        setError(true)
        setReasons(null)
      })
      .finally(
        setLoading(false)
      )

      const getTeacherName = (teacher) => {
        const searchString = teacher
        
        const request = {
            ...loginRequest,
            account: accounts[0]
        }
        
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request)
            .then((response) => {
                callMsGraphStaff(searchString, response.accessToken)
                    .then((response) => {
                        setTeacherName(response.value[0].displayName)
                    })
            })
            .catch((e) => {
                console.log(e.message)
                instance.acquireTokenPopup(request)
                    .then((response) => {
                        callMsGraphStaff(searchString, response.accessToken)
                            .then((response) => {
                                setTeacherName(response.value[0].displayName)
                            })
                    })
            })
    }

    getTeacherName(teacher)
  },[accounts, instance, loginRequest, menu, teacher])

  const getReasons = () => {
    let reasonString = ''
    let count = reason

    if(reason === 0) {
      reasonString = 'No reason given'
    }

    reasons.map(item => {
      if(item.id & reason) {
        reasonString += item.name
        
        count -= item.id

        if(count > 0) {
          reasonString += ', '
        }
      }
      return ''
    })

    return reasonString
  }

  const deleteThisTicket = (e) => {
    e.stopPropagation()

    if(thisTeacher === teacher || admin) {
      deleteTicket(id, menu)
      onDelete(id)
    } else {
      alert('Sorry - not your ticket')
    }
  }

  return (
    <>
      { loading && <div>A moment please...</div> }
      { error && (
        <div
          style={{textAlign: 'center'}}
        >
          There was a problem getting the tickets
        </div>
      )}
      <div
        className='ticket-panel'
      >
        <div className='start-of-card'>
          { menu === 'T' ? 
              <img
                src={ Track }
                style={{ width: '40px' }}
                alt='Track'
              />
            :
              menu === 'C' ?
                <div
                  className='commendation-icon'
                  title='Commendation'
                  onClick={ deleteThisTicket }
                ></div>
              :
              <div
                className='ticket-icon'
                title='Ticket'
                onClick={ deleteThisTicket }
              ></div>
          }
          <div>
            <strong>Teacher: </strong>{ teacherName }<br />
            <strong>Reason: </strong>{ getReasons() }<br />

            { menu === 'T' ?
              <>
                <strong>Time Out: </strong>{ date }<br />
                <strong>Returned: </strong>{ timeIn ? timeIn : 'Still out of class' }<br />
              </>
              :
              <>
                <strong>When: </strong>{ date }<br />
              </>
            }
            { notes &&
              <>
                <strong>Notes: </strong>{ notes }<br />
              </>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default TicketItem