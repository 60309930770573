import React from 'react'
import Search from './Search'

const Stack = () => {
  return (
    <>
      <h1>Stack</h1>
      <Search />
    </>
  )
}

export default Stack