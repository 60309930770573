import React, { useEffect, useState } from 'react'
import { getMenuItems, getTicketColor, getTicketCount, returnStudent } from '../LocalAPICalls'
import { callMsGraphByID } from '../graph'
import Image from './Image'
import Cover from './Cover'
import TrackCard from './TrackCard'

const TrackItem = ({ instance, loginRequest, accounts, id, student, teacher, timeOut, timeIn, reason, notes, onDelete, user, admin }) => {
    const [studentName, setStudentName] = useState('')
    const [pc, setPC] = useState('')
    const [count, setCount] = useState(0)
    const [reasons, setReasons] = useState('')
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [showCard, setShowCard] = useState(false)

    useEffect(() => {
        getMenuItems('T')
            .then((response) => {
                let reasonString = ''
                response.map((item) => {
                    let score = reasons
                    if(reason & item.id) {
                        reasonString += item.name
                        score -= item.id
                        if(score > 0) {
                            reasonString += ', '
                        }
                    } else {
                        reasonString = ''
                    }
                    setReasons(reasonString)
                    return reasonString
                })
            })
            .catch((err) => {
                console.log(err.message)
                setError(true)
            })
            .finally(
                setLoading(false)
            )

        getTicketCount(student, 'T')
            .then((response) => {
                setCount(response[0].count)
            })
            .catch((err) => {
                console.log(err.message)
                setError(true)
            })
            .finally(
                setLoading(false)
            )

        const getStudentDetails = (student) => {
            const searchString = student
            
            const request = {
                ...loginRequest,
                account: accounts[0]
            }
            
            // Silently acquires an access token which is then attached to a request for Microsoft Graph data
            instance.acquireTokenSilent(request)
                .then((response) => {
                    callMsGraphByID(searchString, response.accessToken)
                        .then((response) => {
                            setStudentName(response.value[0].displayName)
                            setPC(response.value[0].officeLocation)
                        })
                })
                .catch((e) => {
                    console.log(e.message)
                    instance.acquireTokenPopup(request)
                        .then((response) => {
                            callMsGraphByID(searchString, response.accessToken)
                                .then((response) => {
                                    setStudentName(response.value[0].displayName)
                                    setPC(response.value[0].officeLocation)
                                })
                        })
                })
        }
        
        getStudentDetails(student)
    },[accounts, instance, loginRequest, reason, reasons, student])

    const showTrackCard = () => {
        setShowCard(true)
    }

    const closeCard = () => {
        setShowCard(false)
    }

    const returnThisStudent = (e) => {
        // let close = false
        // if(teacher !== user) {
        //     document.getElementById('cover').style.display = 'block'
        // }
        //if(close) {
            e.stopPropagation()
            returnStudent(id)
            onDelete(id)
        //}
    }

  return (
    <>
        { loading && <div>A moment please...</div> }
        { error && <h4>An error has occurred</h4> }
        <div
            className='student-panel'
            style={{
                borderLeft: '6px solid ' + getTicketColor(count),
            }}
            onClick={ showTrackCard }
        >
            <div className='start-of-card'>
                <div
                    className='return-student'
                    onClick={ returnThisStudent }
                ></div>

                <div>
                    { studentName } ({ pc })
                </div>
            </div>

            <div>
                <Image
                    id={ student }
                    name={ studentName }
                    size='40px'
                    center='false'
                />
            </div>
        </div>
        { showCard &&
            <>
                <Cover
                    onClick={ closeCard }
                />
                <TrackCard
                    loginRequest={ loginRequest }
                    accounts={ accounts }
                    instance={ instance }
                    id={ id }
                    student={ student }
                    studentName={ studentName }
                    teacher={ teacher }
                    notes={ notes }
                    user={ user }
                    reason={ reason }
                    timeOut={ timeOut }
                    timeIn={ timeIn ? timeIn : 'Still out of class' }
                    onDelete={ returnThisStudent }
                    onClick = { closeCard }
                    pc={ pc }
                />
            </>
        }
    </>
  )
}

export default TrackItem