import React, { useEffect, useState } from 'react'
import Button from './Button'
import { Link } from 'react-router-dom'
import { getStudentLeaderboard } from '../LocalAPICalls'
import LeaderBoard from './LeaderBoard'

const StudentLeaderboard = ({ instance, accounts, loginRequest, admin }) => {
	const [leaderBoard, setLeaderBoard] = useState([])
	const [which, setWhich] = useState('C')
	const [week, setWeek] = useState(11)
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		getStudentLeaderboard(which, week)
			.then((response) => {
				setLeaderBoard(response)
			})
			.catch((err) => {
				console.log(err.message)
				setError(true)
			})
			.finally(setLoading(false))
	}, [which, week])

	const getLeaderBoardTitle = () => {
		switch (which) {
			case 'A':
				return 'Academic Tickets'
			case 'B':
				return 'Behaviour Tickets'
			case 'T':
				return 'Tracks'
			case 'U':
				return 'Uniform Tickets'
			default:
				return 'Commendations'
		}
	}

	return (
		<>
			{admin ? (
				<>
					{loading && <div>A moment please...</div>}
					{error && (
						<div>{`There was problem getting the leaderboard - ${error}`}</div>
					)}
					<h3>Student Leaderboards</h3>
					<Link to='/' className='text-link'>
						<Button title='Main Menu' />
					</Link>
					<Link to='/Admin' className='text-link'>
						<Button title='Admin' />
					</Link>

					{leaderBoard && (
						<LeaderBoard
							instance={instance}
							accounts={accounts}
							loginRequest={loginRequest}
							leaderBoard={leaderBoard}
							title={getLeaderBoardTitle()}
							setWhich={setWhich}
							setWeek={setWeek}
						/>
					)}
				</>
			) : (
				<>
					<p>Sorry, you are not authorised to view this page.</p>
					<Link to='/' className='text-link'>
						<Button title='Main Menu' />
					</Link>
				</>
			)}
		</>
	)
}

export default StudentLeaderboard
