import React, { useEffect, useState } from 'react'
import { callMsGraphByID } from '../graph'
import { getRollOverCount, isRollOver } from '../LocalAPICalls'
import FURoomCard from './FURoomCard'
import Cover from './Cover'
import Image from './Image'
import RollOver from './images/recycle.png'

const FURoomListItem = ({ instance, accounts, loginRequest, id, student }) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [studentName, setStudentName] = useState('')
    const [pc, setPC] = useState('')
    const [showCard, setShowCard] = useState(false)
    const [rollOverCount, setRollOverCount] = useState(0)
    const [rolledOver, setRolledOver] = useState(0)
    const [borderColour, setBorderColour] = useState('green')

    useEffect(() => {
        const searchString = student
        if(searchString !== '') {
            if(searchString && searchString.length >= 1) {
                const request = {
                    ...loginRequest,
                    account: accounts[0]
                };

                // Silently acquires an access token which is then attached to a request for Microsoft Graph data
                instance.acquireTokenSilent(request).then((response) => {
                    callMsGraphByID(searchString, response.accessToken)
                        .then(response => {
                            setStudentName(response.value[0].displayName)
                            setPC(response.value[0].officeLocation)
                            setLoading(false)
                            setError(false)
                        })
                }).catch((e) => {
                    instance.acquireTokenPopup(request)
                        .then((response) => {
                            callMsGraphByID(searchString, response.accessToken)
                                .then(response => {
                                    setStudentName(response.value[0].displayName)
                                    setPC(response.value[0].officeLocation)
                                    setLoading(false)
                                    setError(false)
                                })
                        })
                })
            }
        } else {
            setStudentName('Tommy Tucker')
            setPC('11C')
            setError(true)
        }

        isRollOver(id)
            .then((response) => {
                setRolledOver(response[0].rolledOver)
            })

        getRollOverCount(student)
            .then((response) => {
                let count = 0
                if(response.length > 0) {
                    count = response[0].rollOverCount
                    setRollOverCount(response[0].rollOverCount)
                }

                let bc = 'green'
                
                if(count > 5) {
                    bc = 'orange'
                }
                
                if(count > 10) {
                    bc = 'red'
                }

                setBorderColour(bc)
            })
    }, [accounts, instance, loginRequest, student, id])

    const showDetails = () => {
        setShowCard(!showCard)
    }

    return (
        <>
            { loading && <div>A moment please...</div> }
            { error && <div style={{textAlign: 'center'}}>There was a problem getting the tickets</div> }
            <div
                className='student-panel'
                onClick={ showDetails }
                style={{borderLeft: `solid 6px ${borderColour}`}}
            >
                <div>
                { studentName } ({ pc })
                {(rolledOver > 0 || rollOverCount > 0) &&
                    <br />
                }

                {rolledOver > 0 &&
                    <>
                        <img src={RollOver} alt="Roll over" style={{width: '30px', verticalAlign: 'middle'}} />
                    </>
                }

                {rollOverCount > 0 &&
                    <>
                        &nbsp;&nbsp;{rollOverCount} total rolled over
                    </>
                }
                </div>

                <Image
                    id={ student }
                    name='Test'
                    size='40px'
                    center={ false }
                />
            </div>

            { showCard &&
                <>
                    <Cover
                        onClick={ showDetails }
                    />
                    <FURoomCard
                        id={ id }
                        instance={ instance }
                        accounts={ accounts }
                        loginRequest={ loginRequest }
                        student={ student }
                        studentName={ studentName }
                        pc={ pc }
                        showDetails={ showDetails }
                    />
                </>
            }
        </>
    )
}

export default FURoomListItem