import React from 'react'

const Cover = ({ onClick }) => {
  
  return (
    <div
      id='cover'
      className='cover'
      onClick={ onClick }
    >
    </div>
  )
}

export default Cover