import React, { useEffect, useState } from 'react'
import { getWeeks } from '../LocalAPICalls'
import StudentLeaderBoardItem from './StudentLeaderBoardItem'
import Select from 'react-select'

const LeaderBoard = ({
	instance,
	accounts,
	loginRequest,
	leaderBoard,
	setWhich,
	setWeek,
}) => {
	const notYet = false
	const [register, setRegister] = useState({
		value: 'C',
		label: 'Commendations',
	})

	const [theWeek, setTheWeek] = useState({ value: 11, label: 'Whole Term' })
	const [weeks, setWeeks] = useState([])
	const [yrLevel, setYrLevel] = useState({ value: 0, label: 'All year levels' })

	useEffect(() => {
		getWeeks().then((response) => {
			let theList = response
			theList.unshift({ value: '11', label: 'Whole Term' })
			setWeeks(theList)
		})
	}, [])

	const yrLevels = [
		{
			value: 0,
			label: 'All year levels',
		},
		{
			value: 7,
			label: 'Year 7',
		},
		{
			value: 8,
			label: 'Year 8',
		},
		{
			value: 9,
			label: 'Year 9',
		},
		{
			value: 10,
			label: 'Year 10',
		},
		{
			value: 11,
			label: 'Year 11',
		},
		{
			value: 12,
			label: 'Year 12',
		},
	]

	const registers = [
		{
			value: 'C',
			label: 'Commendations',
		},
		{
			value: 'A',
			label: 'Academic Register',
		},
		{
			value: 'B',
			label: 'Behaviour Register',
		},
		{
			value: 'T',
			label: 'Tracker',
		},
		{
			value: 'U',
			label: 'Uniform Register',
		},
	]

	let place = 1

	const handleWeekChange = (e) => {
		setTheWeek(e)
		setWeek(e.value)
	}

	const handleWhichChange = (e) => {
		setRegister(e)
		setWhich(e.value)
	}
	const handleYrLevelChange = (e) => {
		console.log(e.value)
		setYrLevel(e.value)
	}

	return (
		<>
			{leaderBoard && (
				<>
					<div className='form-control'>
						<label>Register:</label>

						<Select
							placeholder='Choose a register...'
							value={register}
							options={registers}
							onChange={handleWhichChange}
							autoFocus
						/>
					</div>

					<div className='form-control'>
						<label>Week:</label>

						<Select
							placeholder='Choose a week...'
							value={theWeek}
							options={weeks}
							onChange={handleWeekChange}
						/>
					</div>
					{notYet && (
						<div className='form-control'>
							<label>Year Level:</label>

							<Select
								placeholder='Choose a Year Level...'
								value={yrLevel}
								options={yrLevels}
								onChange={handleYrLevelChange}
							/>
						</div>
					)}

					{leaderBoard.map((item) => (
						<StudentLeaderBoardItem
							key={item.student}
							instance={instance}
							accounts={accounts}
							loginRequest={loginRequest}
							which={register.value}
							student={item.student}
							count={item.count}
							place={place++}
						/>
					))}
				</>
			)}
		</>
	)
}

export default LeaderBoard
