import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
	getSportCount,
	getDramaCount,
	getTicketColor,
	getTicketCount,
	getUniform,
	getHeadphones,
	getDLH,
	getGum
} from '../LocalAPICalls'
import Button from './Button'
import Form from './Form'
import Image from './Image'
import TicketList from './TicketList'
import TrafficLights from './TrafficLights'
import Sport from './images/sport.png'
import Drama from './images/drama.png'
import Uniform from './images/uniform.png'
import Headphones from './images/phones.png'
import DLH from './images/hub.png'
import Gum from './images/gum.png'

const Student = ({ instance, accounts, loginRequest, teacher, tName, admin, term }) => {
	const [ticketType, setTicketType] = useState('')
	const [count, setCount] = useState(0)
	const [sport, setSport] = useState(false)
	const [drama, setDrama] = useState(false)
	const [uniform, setUniform] = useState('')
	const [headphones, setHeadphones] = useState('')
	const [dlh, setDLH] = useState('')
	const [gum, setGum] = useState('');
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(true)

	const { student, name, pc, menu, theClass, pct } = useParams()

	useEffect(() => {
		switch (menu) {
			case 'A':
				setTicketType(' Academic Ticket')
				break
			case 'B':
				setTicketType(' Behaviour Ticket')
				break
			case 'U':
				setTicketType(' Uniform Ticket')
				break
			case 'T':
				setTicketType(' Track')
				break
			case 'C':
				setTicketType(' Commendation')
				break
			default:
				setTicketType('')
				break
		}

		getSportCount(student)
			.then((response) => {
				setSport(response[0].sport > 0)
			})
			.catch((err) => {
				console.error('Student Register: ', err.message)
				setError(true)
			})
			.finally(setLoading(false))

		getDramaCount(student)
			.then((response) => {
				setDrama(response[0].drama > 0)
			})
			.catch((err) => {
				console.error('Student Register: ', err.message)
				setError(true)
			})
			.finally(setLoading(false))

		getUniform(student)
			.then((response) => {
				if(response[0].note === undefined || response[0].note === null)
					setUniform('')
				else
					setUniform(response[0].note)
			})
			.catch((err) => {
				console.error('Student Register: ', err.message)
				setError(true)
			})
			.finally(() => {
				setLoading(false)
				setError(false)
			})

		getHeadphones(student)
			.then((response) => {
				if(response[0].note === undefined || response[0].note === null)
					setHeadphones('')
				else
					setHeadphones(response[0].note)
			})
			.catch((err) => {
				console.error('Student Register: ', err.message)
				setError(true)
			})
			.finally(() => {
				setLoading(false)
				setError(false)
			})

		getDLH(student)
			.then((response) => {
				if(response[0].note === undefined || response[0].note === null)
					setDLH('')
				else
					setDLH(response[0].note)
			})
			.catch((err) => {
				console.error('Student Register: ', err.message)
				setError(true)
			})
			.finally(() => {
				setLoading(false)
				setError(false)
			})

			getGum(student)
			.then((response) => {
				if(response[0].note === undefined || response[0].note === null)
					setGum('')
				else
					setGum(response[0].note)
			})
			.catch((err) => {
				console.error('Student Register: ', err.message)
				setError(true)
			})
			.finally(() => {
				setLoading(false)
				setError(false)
			})

		getTicketCount(student, menu)
			.then((response) => {
				setCount(response[0].count)
				setError(false)
			})
			.catch((err) => {
				console.error('Student Register: ', err.message)
				setError(true)
			})
			.finally(() => {
				setLoading(false)
				setError(false)
			})
	}, [student, menu])

	return (
		<div>
			{loading ? <h3>Loading...</h3> : error && <h3>An error has occurred</h3>}
			<h3>{name + ' (' + pc + ')'}</h3>
			<center>
				{count > 0 && (
					<p style={{ color: getTicketColor(count) }}>
						{count + ticketType}
						{count > 1 && 's'}
					</p>
				)}
				<TrafficLights
					student={student}
					menu={menu}
					count={count}
					term={term}
				/>
			</center>

			<Image id={student} name={name} size='150px' center={true} />
			<div style={{ textAlign: 'center' }}>
				{menu === 'U' && sport && (
					<img src={Sport} alt='Sport' />
				)}

				{menu === 'U' && drama && (
					<img src={Drama} alt='Drama' />
				)}

				{uniform !== '' && (
					<img src={Uniform} alt='Uniform Exemption' title={uniform} />
				)}

				{headphones !== '' && (
					<img src={Headphones} alt='Headphone Exemption' title = {headphones} />
				)}

				{dlh !== '' && (
					<img src={DLH} alt='Diverse-Learners Hub Exemption' title = {dlh} />
				)}
				{gum !== '' && (
					<img src={Gum} alt='Gum Exemption' title = {gum} />
				)}
			</div>
			

			<Link className='text-link' to='/'>
				<Button title='Main Menu' />
			</Link>

			<Form
				menu={menu}
				teacher={teacher}
				id={student}
				theClass={theClass}
				sName={name + ' (' + pc + ')'}
				tName={tName}
				pct={pct}
			/>

			{count > 0 && (
				<TicketList
					instance={instance}
					accounts={accounts}
					loginRequest={loginRequest}
					student={student}
					menu={menu}
					admin={admin}
				/>
			)}
		</div>
	)
}

export default Student
