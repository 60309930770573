import {
	graphDataAll,
	graphConfig,
	graphConfigByID,
	graphConfigStaff,
	graphConfigStaffPC,
	graphConfigStaffSearch,
} from './authConfig'

/**
 * Attaches a given access token to a Microsoft Graph API call. Returns information about the user
 */
export async function callMsGraph(searchString, accessToken) {
	const headers = new Headers()
	const bearer = `Bearer ${accessToken}`

	headers.append('Authorization', bearer)
	headers.append('ConsistencyLevel', 'eventual')

	const options = {
		method: 'GET',
		headers: headers,
	}

	return fetch(graphConfig(searchString), options)
		.then((response) => response.json())
		.catch((error) => console.log(error))
}

export const callMSGraphAll = async(accessToken) => {
	const headers = new Headers()
	const bearer = `Bearer ${accessToken}`

	headers.append('Authorization', bearer)
	headers.append('ConsistencyLevel', 'eventual')

	const options = {
		method: 'GET',
		headers: headers,
	}

	return (await fetch(graphDataAll(), options)).json()
}

export async function callMsGraphByID(searchString, accessToken) {
	const headers = new Headers()
	const bearer = `Bearer ${accessToken}`

	headers.append('Authorization', bearer)
	headers.append('ConsistencyLevel', 'eventual')

	const options = {
		method: 'GET',
		headers: headers,
	}

	return fetch(graphConfigByID(searchString), options)
		.then((response) => response.json())
		.catch((error) => console.log(error))
}

export async function callMsGraphStaff(searchString, accessToken) {
	const headers = new Headers()
	const bearer = `Bearer ${accessToken}`

	headers.append('Authorization', bearer)
	headers.append('ConsistencyLevel', 'eventual')

	const options = {
		method: 'GET',
		headers: headers,
	}

	return fetch(graphConfigStaff(searchString), options)
		.then((response) => response.json())
		.catch((error) => console.log(error))
}

export async function callMsGraphStaffPC(accessToken) {
	const headers = new Headers()
	const bearer = `Bearer ${accessToken}`

	headers.append('Authorization', bearer)
	headers.append('ConsistencyLevel', 'eventual')

	const options = {
		method: 'GET',
		headers: headers,
	}

	return fetch(graphConfigStaffPC(), options)
		.then((response) => response.json())
		.catch((error) => console.log(error))
}

export async function callMsGraphStaffSearch(searchString, accessToken) {
	const headers = new Headers()
	const bearer = `Bearer ${accessToken}`

	headers.append('Authorization', bearer)
	headers.append('ConsistencyLevel', 'eventual')

	const options = {
		method: 'GET',
		headers: headers,
	}

	return fetch(graphConfigStaffSearch(searchString), options)
		.then((response) => response.json())
		.catch((error) => console.log(error))
}
