import React, {useState, useEffect} from 'react'
import { getTickets } from '../LocalAPICalls'
import { format } from 'date-fns'
import TicketItem from './TicketItem'

const TicketList = ({ instance, accounts, loginRequest, student, menu, admin }) => {
  const [title, setTitle] = useState('')
  const [tickets, setTickets] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    switch(menu) {
      case 'A':
        setTitle('Academic Ticket List')
        break
      case 'B':
        setTitle('Behaviour Ticket List')
        break
      case 'T':
        setTitle('Previous Tracks')
        break
      case 'U':
        setTitle('Uniform Ticket List')
        break
      default:
        setTitle('Commendation List')
        break
    }

    // Get Tickets from API
    getTickets(student, menu)
    .then((response) => {
      setTickets(response)
    })
    .catch((err) => {
      setError(true)
      console.log(err.message)
    })
    .finally(
      setLoading(false)
    )
  }, [menu, student])

  const deleteTicket = (id) => {
    setTickets(tickets.filter((ticket) =>
    ticket.id !== id))
  }

  return (
    <div>
      { loading && <div>A moment please...</div> }
      { error && (
        <div
          style={{textAlign: 'center'}}
        >
          There was a problem getting the tickets
        </div>
      )}

      <h3>{ title }</h3>
      { tickets.map((item) => (
            <TicketItem
              key={ item.id }
              id={ item.id }
              instance={ instance }
              accounts={ accounts }
              loginRequest={ loginRequest }
              reason={ item.reason }
              teacher={ item.teacher }
              notes={ item.notes }
              date={ format(new Date(item.date), 'dd/MM/yyyy h:mma') }
              timeIn={ item.timeIn ? format(new Date(item.timeIn), 'dd/MM/yyyy h:mma') : null }
              menu={ menu }
              onDelete={ deleteTicket }
              admin={ admin }
            />
      )) }
    </div>
  )
}

export default TicketList