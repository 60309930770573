import React, { useEffect, useState } from 'react'
import { callMsGraphStaff } from '../graph'
import Image from './Image';
import { Link } from 'react-router-dom';

const TeacherLeaderboardItem = ({ instance, accounts, loginRequest, teacher, which, count, place }) => {
  const [teacherName, setTeacherName] = useState('');

  useEffect(() => {
    const getTeacherName = () => {
        const searchString = teacher
        
        const request = {
            ...loginRequest,
            account: accounts[0]
        }
        
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request)
            .then((response) => {
                callMsGraphStaff(searchString, response.accessToken)
                    .then((response) => {
                        setTeacherName(response.value[0].displayName)
                    })
            })
            .catch((e) => {
                console.log(e.message)
                instance.acquireTokenPopup(request)
                    .then((response) => {
                        callMsGraphStaff(searchString, response.accessToken)
                            .then((response) => {
                                setTeacherName(response.value[0].displayName)
                            })
                    })
            })
    }

    getTeacherName()
  }, [accounts, instance, loginRequest, teacher])

  const getLabel = () => {
    let label = ''

    if(which === 'T') {
        label = 'track'
    } if(which === 'C') {
        label = 'commendation'
    } else {
        label = 'ticket'
    }

    if(count > 1) {
        label += 's'
    }

    return label
  }

  return (
    <Link
        className='student-list-link text-link'
        to={ '/Teacher/' +
            teacher +
            '/' + teacherName +
            '/' + which
        }
    >

        <div
            className='student-panel'
        >
            <div className='start-of-card'>
                <div className='place'>{ place }</div>
                <div>{ teacherName }<br />{ count } { getLabel() }</div>
            </div>
            <Image
                id={ teacher }
                name={ teacherName }
                size='40px'
                center={ false }
            />
        </div>
    </Link>
  )
}

export default TeacherLeaderboardItem