import React, { useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import { getTeacherTicketCount } from "../LocalAPICalls"
import Button from "./Button"
import Image from "./Image"
import TeacherTicketList from "./TeacherTicketList"
import Select from "react-select"

const Teacher = ({ instance, accounts, loginRequest, admin }) => {
	const [ticketType, setTicketType] = useState("")
	const [count, setCount] = useState(0)
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(true)
	const [register, setRegister] = useState({
		value: "C",
		label: "Commendations",
	})

	const { tID, name } = useParams()

	const menus = [
		{
			value: "A",
			label: "Academic Register",
		},
		{
			value: "B",
			label: "Behaviour Register",
		},
		{
			value: "T",
			label: "Tracker",
		},
		{
			value: "U",
			label: "Uniform Register",
		},
		{
			value: "C",
			label: "Commendations",
		},
	]

	useEffect(() => {
		switch (register.value) {
			case "A":
				setTicketType(" Academic Ticket")
				break
			case "B":
				setTicketType(" Behaviour Ticket")
				break
			case "U":
				setTicketType(" Uniform Ticket")
				break
			case "T":
				setTicketType(" Track")
				break
			case "C":
				setTicketType(" Commendation")
				break
			default:
				setTicketType("")
				break
		}

		getTeacherTicketCount(tID, register.value)
			.then((response) => {
				setCount(response[0].count)
				setError(false)
				setLoading(false)
			})
	}, [register, tID])

	const getTheTickets = (e) => {
		setRegister(e)
	}

	return (
		<div>
			{loading ? <h3>Loading...</h3> : error && <h3>An error has occurred</h3>}
			<h3>{name}</h3>
			<center>
				{count > 0 && (
					<p>
						{count + ticketType}
						{count > 1 && "s"}
					</p>
				)}
			</center>

			<Image id={tID} name={name} size='150px' center={true} />
			<Link className='text-link' to='/'>
				<Button title='Main Menu' />
			</Link>

      <div style={{ marginTop: '20px' }}>
        <Select
          options={menus}
          value={register}
          onChange={getTheTickets}
          autoFocus
        />

        {count > 0 && (
          <TeacherTicketList
            instance={instance}
            accounts={accounts}
            loginRequest={loginRequest}
            teacher={tID}
            menu={register.value}
            noTitle={true}
          />
			  )}
      </div>
		</div>
	)
}

export default Teacher
