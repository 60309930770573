import React from 'react'
import AdminDisplayItem from './AdminDisplayItem'

const AdminDisplay = ({ graphData }) => {
  return (
    <>
            { graphData &&
                graphData.value.map((t) => (
                        <AdminDisplayItem
                            key={ t.userPrincipalName }
                            teacher={ t.userPrincipalName.split('@')[0].toLowerCase() }
                            name={ t.displayName }
                        />
                    )
                )
            }
        </>
  )
}

export default AdminDisplay