export const msalConfig = {
	auth: {
		clientId: `${process.env.REACT_APP_CLIENT_ID}`,
		authority: `${process.env.REACT_APP_AUTHORITY}`,
		redirectUri: `${process.env.REACT_APP_REDIRECTURI}`,
	},
	cache: {
		cacheLocation: 'sessionStorage', // This configures where your cache will be stored
		storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
	},
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
	scopes: ['User.Read.All'],
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = (searchString) => {
	// graphMeEndpoint: 'https://graph.microsoft.com/v1.0/users/insertName@sol.qld.edu.au?$select=givenName,surname,displayName,userPrincipalName,officeLocation'
	return (
		"https://graph.microsoft.com/v1.0/users?$count=true&$filter=endsWith(userPrincipalName,'sol.qld.edu.au') AND (officeLocation ge ' ') AND NOT (startsWith(displayName,'Design')) AND (startsWith(givenName,'" +
		searchString +
		"') OR startsWith(surname,'" +
		searchString +
		"') OR startsWith(officeLocation,'" +
		searchString +
		"') OR startsWith(userPrincipalName,'" +
		searchString +
		"'))&$orderBy=displayName&$select=displayName,userPrincipalName,givenName,surname,officeLocation"
	)
}

export const graphDataAll = () => {
	return "https://graph.microsoft.com/v1.0/users?$count=true&$filter=endsWith(userPrincipalName, 'sol.qld.edu.au') AND (officeLocation ge ' ') and accountEnabled eq true&$select=userPrincipalName,givenName,surname,officeLocation&$top=400"
}

export const graphConfigByID = (searchString) => {
	// graphMeEndpoint: 'https://graph.microsoft.com/v1.0/users/insertName@sol.qld.edu.au?$select=givenName,surname,displayName,userPrincipalName,officeLocation'
	return (
		"https://graph.microsoft.com/v1.0/users?$count=true&$filter=startsWith(userPrincipalName,'" +
		searchString +
		"') AND (officeLocation ge ' ')&$select=displayName,userPrincipalName,givenName,surname,officeLocation"
	)
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfigStaff = (searchString) => {
	//searchString = searchString.trim()
	// graphMeEndpoint: 'https://graph.microsoft.com/v1.0/users/insertName@sol.qld.edu.au?$select=givenName,surname,displayName,userPrincipalName,officeLocation'
	return (
		"https://graph.microsoft.com/v1.0/users?$count=true&$filter=startsWith(userPrincipalName,'" +
		searchString +
		"@lords.qld.edu.au')&$select=displayName,userPrincipalName"
	)
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfigStaffPC = () => {
	//searchString = searchString.trim()
	// graphMeEndpoint: 'https://graph.microsoft.com/v1.0/users/insertName@sol.qld.edu.au?$select=givenName,surname,displayName,userPrincipalName,officeLocation'
	return "https://graph.microsoft.com/v1.0/users?$count=true&$filter=endsWith(userPrincipalName, 'lords.qld.edu.au') AND (officeLocation ge ' ') and accountEnabled eq true&$select=userPrincipalName,givenName,surname,officeLocation&$top=50"	
}

export const graphConfigStaffSearch = (searchString) => {
	//searchString = searchString.trim()
	// graphMeEndpoint: 'https://graph.microsoft.com/v1.0/users/insertName@sol.qld.edu.au?$select=givenName,surname,displayName,userPrincipalName,officeLocation'
	return (
		"https://graph.microsoft.com/v1.0/users?$count=true&$filter=endsWith(userPrincipalName, '@lords.qld.edu.au') AND (startsWith(givenName,'" +
		searchString +
		"') OR startsWith(surname, '" +
		searchString +
		"'))&$select=displayName,userPrincipalName"
	)
}
