import React from 'react'
import DisplayItem from './DisplayItem'

const Display = ({ graphData, menu, admin, pcTeachers }) => {
    return (
        <>
            { graphData &&
                graphData.value.map((s) => (
                        <DisplayItem
                            key={ s.userPrincipalName }
                            student={ s.userPrincipalName.split('@')[0] }
                            name={ s.displayName }
                            menu={ menu }
                            pc={ s.officeLocation }
                            admin={ admin }
                            pcTeachers={ pcTeachers }
                        />
                    )
                )
            }
        </>
    )
}

export default Display