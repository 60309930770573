import React from 'react'
import { Link } from 'react-router-dom'
import Logo from './images/logo.png'

const Header = () => {

  return (
    <header>
      <h2>LORDS<br />Student Register</h2>
      <Link
        to='/Admin'
        className='text-link'
      >
        <img
          src={ Logo }
          alt='LORDS Logo'
          className='main-logo'
        />
      </Link>
    </header>
  )
}

export default Header