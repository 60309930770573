import React from 'react'
import ClassListItem from './ClassListItem'

const ClassList = ({
	instance,
	accounts,
	loginRequest,
	classList,
	menu,
	theClass,
}) => {
	return (
		<div>
			{classList.map((s) => (
				<ClassListItem
					key={s.id}
					student={s.id}
					instance={instance}
					accounts={accounts}
					loginRequest={loginRequest}
					menu={menu}
					theClass={theClass}
				/>
			))}
		</div>
	)
}

export default ClassList
