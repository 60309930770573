import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import Button from './Button'
import ClassList from './ClassList'
import { Link, useParams } from 'react-router-dom'
import { getMyClasses, getClassList } from '../LocalAPICalls'

const MyClasses = ({ instance, accounts, loginRequest, teacher }) => {
	const [myClasses, setMyClasses] = useState([])
	const [theClass, setTheClass] = useState(null)
	const [classList, setClassList] = useState([])
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(true)
	const [register, setRegister] = useState(
		{
			value: 'C',
			label: 'Commendations',
		}
	)

	const { myClass } = useParams()

	const registers = [
		{
			value: 'C',
			label: 'Commendations',
		},
		{
			value: 'A',
			label: 'Acadmic Register',
		},
		{
			value: 'B',
			label: 'Behaviour Register',
		},
		{
			value: 'T',
			label: 'Tracker',
		},
		{
			value: 'U',
			label: 'Uniform Register',
		},
	]

	useEffect(() => {
		const getYearLevel = (theID) => {
			let yrLevel = theID.substring(0, 2)

			if (yrLevel[0] === '0') {
				return yrLevel[1]
			}

			if (yrLevel === '89') {
				return '8/9'
			}

			return yrLevel
		}

		getMyClasses(teacher)
			.then((response) => {
				response.map((element) => {
					element.label = getYearLevel(element.value) + ' ' + element.label
					if (element.value === myClass) {
						setTheClass(element)
						getClassList(element.value).then((response) => setClassList(response))
					}
					return ''
				})
				setMyClasses(response)
				setError(false)
				return
			})
			.catch((err) => {
				console.log(err.message)
				setError(true)
			})
			.finally(setLoading(false))
	}, [classList, myClass, teacher])

	const showClass = (e) => {
		setTheClass(e)
		getClassList(e.value).then((response) => setClassList(response))
	}

	const changeRegister = (e) => {
		setRegister(e)
	}

	return (
		<>
			{loading && <div>A moment please...</div>}
			{error && (
				<div>{`There was problem getting your classes - ${error}`}</div>
			)}
			<h3>My Classes</h3>
			<Link to='/' className='text-link'>
				<Button title='Main Menu' />
			</Link>
			<Link to='/Admin' className='text-link'>
				<Button title='Admin' />
			</Link>

			<div className='form-control'>
				{myClasses && (
					<>
						<label>Classes:</label>
						<Select
							placeholder='Select a class...'
							onChange={showClass}
							options={myClasses}
							value={theClass}
							autoFocus
						/>

						<label>Register:</label>
						<Select
							placeholder='Select a register...'
							onChange={changeRegister}
							options={registers}
							value={register}
						/>
					</>
				)}
				{theClass ? (
					<ClassList
						classList={classList}
						theClass={theClass.value}
						instance={instance}
						accounts={accounts}
						loginRequest={loginRequest}
						menu={register && register.value}
					/>
				) : (
					<div style={{ minHeight: '180px' }}></div>
				)}
			</div>
		</>
	)
}

export default MyClasses
