import React from 'react'
import { Link } from 'react-router-dom'
import Button from './Button'
import Badge from './images/badge.png'
import AdminSearch from './AdminSearch'
import { cleanUp as APICleanUp } from '../LocalAPICalls'

function Admin({ instance, accounts, loginRequest, admin, teacher }) {
	const cleanUp = () => {
		APICleanUp()
		alert('Test data removed.')
	}
	return (
		<>
			<h3>
				Admin
				{admin && (
					<>
						&nbsp;
						<img src={Badge} alt='Admin User' width='15px' />
					</>
				)}
			</h3>
			<Link to='/' className='text-link'>
				<Button title='Main Menu' />
			</Link>

			<br />

			<Link to={'/MyClasses/none'} className='text-link'>
				<Button title='My Classes' />
			</Link>

			<Link to={'/MyTickets'} className='text-link'>
				<Button title='My Tickets' />
			</Link>

			{admin && (
				<>
					{/* <Link to={ '/AppSettings/' + admin } className='text-link'><Button title='App Settings' /></Link> */}
					<Link to={'/StudentLeaderboard'} className='text-link'>
						<Button title='Student Leaderboards' />
					</Link>

					<Link to={'/StaffLeaderboard'} className='text-link'>
						<Button title='Staff Leaderboards' />
					</Link>
				</>
			)}

			<Link to={'/FURoom'} className='text-link'>
				<Button title='Follow-up Room' />
			</Link>

			{admin && (
				<>
					<Link to='/Reports' className='text-link'>
						<Button title='Reports' />
					</Link>

					{/* <Link to='/Spinner' className='text-link'>
						<Button title='Commendation Spinner' />
					</Link> */}
				</>
			)}

			<Link to={'/Feedback'} className='text-link'>
				<Button title='Feedback and Suggestions' />
			</Link>

			{teacher === 'stephen.blair' && (
				<>
					<Button title='Clean up Tommy Tucker' onClick={cleanUp} />

					<Link to={'/Test'} className='text-link'>
						<Button title='Dev Tests' />
					</Link>
				</>
			)}

			{admin && (
				<AdminSearch
					instance={instance}
					accounts={accounts}
					loginRequest={loginRequest}
					admin={admin}
				/>
			)}
		</>
	)
}

export default Admin
