import React from 'react'
import { Link } from 'react-router-dom'
import Button from './Button'
import Search from './Search'
import TrackerList from './TrackerList'

const Tracker = ({ instance, accounts, loginRequest, teacher, admin }) => {

  return (
    <>
        <h3>Student Tracker</h3>
        <Link className='text-link' to='/'><Button title='Main Menu'/></Link>
        
        <Search
          instance={ instance }
          accounts={ accounts }
          loginRequest={ loginRequest }
          teacher={ teacher }
          menu='T'
        />
        <TrackerList
          instance={ instance }
          accounts={ accounts }
          loginRequest={ loginRequest }
          user={ teacher }
          admin={ admin }
        />
    </>
  )
}

export default Tracker