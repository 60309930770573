import React from 'react'
import { Link } from 'react-router-dom'
import Image from './Image'

const AdminDisplayItem = ({ teacher, name }) => {
  return (
    <>
        <Link
            className='student-list-link text-link'
            to={ '/Teacher/' +
                    teacher +
                    '/' +
                    name +
                    '/A'
                }
        >
            <div
                className='student-panel'
            >
                { name }
                <Image
                    id={ teacher }
                    name={ name }
                    size='40px'
                    center={ false }
                />
            </div>
        </Link>
    </>
  )
}

export default AdminDisplayItem