import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import Select from 'react-select'
import Button from './Button'
import PopUp from './PopUp'
import Cover from './Cover'
import {
	addToFURoom,
	getMenuItems,
	getNextWeek
} from '../LocalAPICalls'

const Form = ({ menu, teacher, id, theClass, sName, tName, pct }) => {
	const [menuItems, setMenuItems] = useState(null)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [reason, setReason] = useState(0)
	const [showPopUp, setShowPopUp] = useState(false)
	const [showFURoom, setShowFURoom] = useState(false)
	const [nextWeek, setNextWeek] = useState([]) // All the dates - this works
	const [fuDate, setFuDate] = useState(null) // should be today's date
	const [status, setStatus] = useState(0)
	const [count, setCount] = useState(0)

	console.log(pct)

	const baseURL = 'https://api.lordstech.online/'

	let mainTitle = ''

	switch (menu) {
		case 'A':
			mainTitle = 'Academic Register'
			break
		case 'B':
			mainTitle = 'Behaviour Register'
			break
		case 'T':
			mainTitle = 'Student Tracker'
			break
		case 'U':
			mainTitle = 'Uniform Register'
			break
		default:
			mainTitle = 'Commendations'
			break
	}

	useEffect(() => {
		// Fetch the data
		getMenuItems(menu)
			.then((response) => {
				setMenuItems(response)
			})
			.catch((err) => {
				console.error('Student Register: ', err.message)
				setError(true)
				setMenuItems(null)
			})
			.finally(() => {
				setLoading(false)
			})

		getNextWeek().then((response) => {
			setNextWeek(response)

			const today = format(new Date(), 'yyyy-MM-dd')
			let count = 0
			let nextDate = response[0]

			while(today >= response[count].value) {
				nextDate = response[count++]
			}

			setFuDate(nextDate)
		})
	}, [menu])

	const makeReasonString = () => {
		let reasonString = ''

		menuItems.map((item) => {
			if (reason & item.id) {
				reasonString += item.name + ', '
			}
			return ''
		})
		reasonString = reasonString.substring(0, reasonString.length - 2)

		return reasonString
	}

	const changeReason = (e) => {
		const amount = parseInt(e.target.value)
		const category = parseInt(e.target.title)

		if (e.target.checked) {
			setReason(reason + amount)
			setStatus(status + category)
			setCount(count + 1)
		} else {
			setReason(reason - amount)
			setStatus(status - category)
			setCount(count - 1)
		}
	}

	const changeComReason = (e) => {
		const amount = parseInt(e.target.value)

		setReason(amount)
	}

	const submitForm = (e) => {
		e.currentTarget.disabled = true
		const notes = document.getElementById('notes').value

		if ((reason === 0 || notes === '') && menu === 'C') {
			alert('You must select a reason and type a note')
		} else if (reason === 0 && notes === '') {
			alert('You must select a reason or type a note')
		} else {
			let fuDateString = ''
			
			if(showFURoom) {
				fuDateString = fuDate.label
			}

			fetch(`${baseURL}?post=${menu}&teacher=${teacher}&tName=${tName}&student=${id}&sName=${sName}&reason=${reason}&notes=${notes}&fuDate=${fuDateString}&pct=${pct}`,{Accept: 'application/json'})
				.then((response) => {
					if (!response.ok) {
						throw new Error(
							`This is an HTTP error: The status is ${response.status}`
						)
					}
					return response.json()
				})
				.catch((err) => {
					console.error('Student Register: ', err.message)
					setMenuItems(null)
				})
				.finally(() => {
					showThePopUp()
					window.scrollTo(0, 0)
				})

			setLoading(true)
			setError(false)
			setMenuItems(null)
			// Deal with FURoom
			if (showFURoom) {
				addToFURoom(id, teacher, fuDate.value, makeReasonString(reason), notes)
			}
		}
	}

	const showThePopUp = () => {
		setShowPopUp(true)
	}

	const closeThePopUp = () => {
		setShowPopUp(false)
	}

	const fuRoom = () => {
		setShowFURoom(!showFURoom)
	}

	const upDateDate = (e) => {
		setFuDate(e)
	}

	return (
		<>
			{loading && <div><h2>A moment please...</h2></div>}
			{error && <div>{`There was problem getting the form - ${error}`}</div>}

			{!loading &&
			<>
			<h2>
				<center>{mainTitle}</center>
			</h2>

			<div className={menu === 'C' ? 'the-form' : 'the-form'}>
				{menu === 'C' && menuItems && (
					<>
						<h4 style={{ marginTop: 0 }}>Ways of Thinking:</h4>
						{menuItems
							.filter((item) => item.category === 'Ways of Thinking')
							.map(({ id, name }) => (
								<label key={id}>
									<input
										type='radio'
										name='commendations'
										value={id}
										onClick={changeComReason}
									/>
									&nbsp;&nbsp;
									<span className='no-bold'>{name}</span>
								</label>
							))}
					</>
				)}

				{menu === 'C' && menuItems && (
					<>
						<h4>Ways of Learning:</h4>
						{menuItems
							.filter((item) => item.category === 'Ways of Learning')
							.map(({ id, name }) => (
								<label key={id}>
									<input
										type='radio'
										name='commendations'
										value={id}
										onClick={changeComReason}
									/>
									&nbsp;&nbsp;
									<span className='no-bold'>{name}</span>
								</label>
							))}
					</>
				)}

				{menu === 'C' && menuItems && (
					<>
						<h4>Ways of Being:</h4>
						{menuItems
							.filter((item) => item.category === 'Ways of Being')
							.map(({ id, name }) => (
								<label key={id}>
									<input
										type='radio'
										name='commendations'
										value={id}
										onClick={changeComReason}
									/>
									&nbsp;&nbsp;
									<span className='no-bold'>{name}</span>
								</label>
							))}
					</>
				)}

				{(menu === 'A' || menu === 'B') && menuItems && (
					<>
						<h4>Low Level:</h4>
						{menuItems
							.filter((item) => item.category === '1')
							.map(({ id, name }) => (
							<label key={id}>
								<input type='checkbox'
									value={id}
									onClick={changeReason}
								/>
								&nbsp;&nbsp;
								<span className='no-bold'>
									{name}
								</span>
							</label>
						))}
					</>
				)}
				
				{(menu === 'A' || menu === 'B') && menuItems && (
					<>
						<h4>Medium Level:</h4>
						{menuItems
							.filter((item) => item.category === '2')
							.map(({ id, name }) => (
							<label key={id}>
								<input type='checkbox'
									value={id}
									onClick={changeReason}
								/>
								&nbsp;&nbsp;
								<span className='no-bold'>
									{name}
								</span>
							</label>
						))}
					</>
				)}

				{(menu === 'A' || menu === 'B') && menuItems && (
					<>
						<h4>High Level:</h4>
						{menuItems
							.filter((item) => item.category === '3')
							.map(({ id, name }) => (
							<label key={id}>
								<input type='checkbox'
									value={id}
									onClick={changeReason}
								/>
								&nbsp;&nbsp;
								<span className='no-bold'>
									{name}
								</span>
							</label>
						))}
					</>
				)}

				{(menu === 'T' || menu === 'U') && menuItems && (
					<>
						<h4>Reasons:</h4>
						{menuItems
							.map(({ id, name }) => (
							<label key={id}>
								<input type='checkbox'
									value={id}
									onClick={changeReason}
								/>
								&nbsp;&nbsp;
								<span className='no-bold'>
									{name}
								</span>
							</label>
						))}
					</>
				)}
			</div>
			{menu !== 'T' && menu !== 'C' && (
				<div
					className='the-form'
					style={{ borderTop: 'solid 2px rgb(122, 49, 57)' }}
				>
					{/* <label>
						<input type='checkbox' disabled value='1' onClick={notifyPCT} />
						&nbsp;&nbsp;
						<span className='no-bold' style={{ color: 'rgb( 34, 84, 159)' }}>
							Notify PCT
						</span>
					</label> */}

					<label>
						<input type='checkbox' value='1' onClick={fuRoom} />
						&nbsp;&nbsp;
						<span className='no-bold' style={{ color: 'rgb( 34, 84, 159)' }}>
							Follow-up Room
						</span>
					</label>
				</div>
			)}

			{showFURoom ? (
				<div className='form-control'>
					<label>
						Follow-up room date:
						<Select
							placeholder='Upcoming dates...'
							value={fuDate}
							options={nextWeek}
							onChange={upDateDate}
						/>
					</label>
				</div>
			) : null}

			<div className='form-control'>
				<label>
					Notes:
					<textarea
						id='notes'
						placeholder='Type any extra information here...'
						rows='4'
					/>
				</label>
			</div>

			<Button onClick={submitForm} title='Submit' />
			</>}
			{showPopUp && (
				<>
					<Cover show={false} onClick={closeThePopUp} />
					<PopUp whereFrom={menu} theClass={theClass} />
				</>
			)}
		</>
	)
}

export default Form
