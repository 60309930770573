import React from 'react'
import { Link } from 'react-router-dom'
import Button from './Button'

const Spinner = () => {
  return (
    <>
        <h3>Spinner</h3>

        <Link className='text-link' to='/'>
            <Button title='Main Menu' />
        </Link>
      
        <Link className='text-link' to='/admin'>
            <Button title='Admin' />
        </Link>
    </>
  )
}

export default Spinner