import React, { useEffect, useState } from 'react'
import { getMenuItems, deleteTicket } from '../LocalAPICalls'
import { callMsGraphByID } from '../graph'
import Track from './images/track.png'

const TeacherTicketItem = ({ instance, accounts, loginRequest, reason, student, notes, date, timeIn, menu, id, onDelete }) => {
  const [reasons, setReasons] = useState([])
  const [studentName, setStudentName] = useState('')
  const [pc, setPC] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getMenuItems(menu)
      .then((response) => {
        setReasons(response)
      })
      .catch((err) => {
        console.log(err.message)
        setError(true)
        setReasons(null)
      })
      .finally(
        setLoading(false)
      )

      const getStudentDetails = (student) => {
        const searchString = student
        
        const request = {
            ...loginRequest,
            account: accounts[0]
        }
        
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request)
          .then((response) => {
            callMsGraphByID(searchString, response.accessToken)
              .then((response) => {
                  setStudentName(response.value[0].displayName)
                  setPC(response.value[0].officeLocation)
              })
          })
            .catch((e) => {
                console.log(e.message)
                instance.acquireTokenPopup(request)
                  .then((response) => {
                    callMsGraphByID(searchString, response.accessToken)
                      .then((response) => {
                          setStudentName(response.value[0].displayName)
                          setPC(response.value[0].officeLocation)
                      })
                  })
            })
    }

    getStudentDetails(student)
  },[accounts, instance, loginRequest, menu, student])

  const getReasons = () => {
    let reasonString = ''
    let count = reason

    if(reason === 0) {
      reasonString = 'No reason given'
    }

    reasons.map(item => {
      if(item.id & reason) {
        reasonString += item.name
        
        count -= item.id

        if(count > 0) {
          reasonString += ', '
        }
      }
      return ''
    })

    return reasonString
  }

  const deleteThisTicket = (e) => {
    e.stopPropagation()
    console.log(id, menu)
    deleteTicket(id, menu)
    onDelete(id)
  }

  return (
    <>
      { loading && <div>A moment please...</div> }
      { error && (
        <div
          style={{textAlign: 'center'}}
        >
          There was a problem getting the tickets
        </div>
      )}
      <div
        className='ticket-panel'
      >
        { menu === 'T' ? 
            <img
              src={ Track }
              style={{ width: '40px' }}
              alt='Track'
            />
          :
            menu === 'C' ?
              <div
                className='commendation-icon'
                title='Commendation'
                onClick={ deleteThisTicket }
              ></div>
            :
            <div
              className='ticket-icon'
              title='Ticket'
              onClick={ deleteThisTicket }
            ></div>
        }

        <div style={{ marginLeft: '30px' }}>
          <strong>Student: </strong>{ studentName } ({ pc })<br />
          <strong>Reason: </strong>{ getReasons() }<br />

          { menu === 'T' ?
            <>
              <strong>Time Out: </strong>{ date }<br />
              <strong>Returned: </strong>{ timeIn }<br />
            </>
            :
            <>
              <strong>When: </strong>{ date }<br />
            </>
          }
          { notes &&
            <>
              <strong>Notes: </strong>{ notes }<br />
            </>
          }
        </div>
      </div>
    </>
  )
}

export default TeacherTicketItem