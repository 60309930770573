import React from 'react'
import Button from './Button'
import { sendFeedback } from '../LocalAPICalls'
import { useState } from 'react'
import { Link } from 'react-router-dom'

const Feedback = ({ who }) => {
    const [suggestion, setSuggestion] = useState('')

    const change = (e) => {
        setSuggestion(e.target.value)
    }

    const send = () => {
        sendFeedback(who, suggestion)
        alert('Your feedback has been sent. Thank you.')
        window.location = '/Feedback'
    }

    return (
        <>
            <h3>Feedback</h3>
            
            <Link className='text-link' to='/'>
                <Button title='Main Menu' />
            </Link>

            <Link className='text-link' to='/Admin'>
                <Button title='Admin' />
            </Link>

            <div className='form-control'>
                <label>
                    Ideas, feedback or issues:
                    <textarea
                        autoFocus
                        id='notes'
                        placeholder='Type any extra information here...'
                        rows='4'
                        onKeyUp={change}
                    />
                </label>
            </div>

            <Button title='Submit' onClick={send} />
        </>
    )
}

export default Feedback