import React, { useState, useEffect } from 'react'
import { callMsGraphStaff } from '../graph'
import Image from './Image'
import { Link } from 'react-router-dom'
import Button from './Button'
import TeacherTicketList from './TeacherTicketList'
import Select from 'react-select'
import { getTeacherTicketCount } from '../LocalAPICalls'

const MyTickets = ({ instance, accounts, loginRequest, teacher }) => {
	const [teacherName, setTeacherName] = useState('')
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false)
	const [register, setRegister] = useState({
		value: 'A',
		label: 'Academic Register',
	})
	const [ticketCount, setTicketCount] = useState(0)
	const [title, setTitle] = useState('')

	const menus = [
		{
			value: 'A',
			label: 'Academic Register',
		},
		{
			value: 'B',
			label: 'Behaviour Register',
		},
		{
			value: 'T',
			label: 'Tracker',
		},
		{
			value: 'U',
			label: 'Uniform Register',
		},
	]

	useEffect(() => {
		const getTeacherName = () => {
			const searchString = teacher

			const request = {
				...loginRequest,
				account: accounts[0],
			}

			// Silently acquires an access token which is then attached to a request for Microsoft Graph data
			instance
				.acquireTokenSilent(request)
				.then((response) => {
					callMsGraphStaff(searchString, response.accessToken).then(
						(response) => {
							setTeacherName(response.value[0].displayName)
							setLoading(false)
							setError(false)
						}
					)
				})
				.catch((e) => {
					console.log(e.message)
					instance.acquireTokenPopup(request).then((response) => {
						callMsGraphStaff(searchString, response.accessToken).then(
							(response) => {
								setTeacherName(response.value[0].displayName)
								setLoading(false)
								setError(false)
							}
						)
					})
				})
		}

		getTeacherName()

		switch (register.value) {
			case 'A':
				setTitle('Academic Ticket')
				break
			case 'B':
				setTitle('Behaviour Ticket')
				break
			case 'T':
				setTitle('Track')
				break
			case 'U':
				setTitle('Uniform Ticket')
				break
			default:
				setTitle('Commendation')
				break
		}

		getTeacherTicketCount(teacher, register.value).then((response) => {
			setTicketCount(response[0].count)
		})
	}, [register, accounts, instance, loginRequest, teacher])

	const getTheTickets = (e) => {
		setRegister(e)
		console.log(e)
	}

	return (
		<div>
			{loading ? <h3>Loading...</h3> : error && <h3>An error has occurred</h3>}
			<h3>{teacherName}</h3>
			{ticketCount > 0 && (
				<center>
					{ticketCount} {title}
					{ticketCount > 1 && 's'}
				</center>
			)}

			<Image id={teacher} name={teacherName} size='150px' center={true} />
			<Link className='text-link' to='/'>
				<Button title='Main Menu' />
			</Link>

			<Link className='text-link' to='/Admin'>
				<Button title='Admin' />
			</Link>

			<div style={{ marginTop: '20px' }}>
				<Select
					options={menus}
					value={register}
					onChange={getTheTickets}
					autoFocus
				/>
				<div style={{ marginTop: '20px' }}>
					<TeacherTicketList
						instance={instance}
						accounts={accounts}
						loginRequest={loginRequest}
						teacher={teacher}
						menu={register.value}
						noTitle={true}
					/>
				</div>
			</div>
		</div>
	)
}

export default MyTickets
