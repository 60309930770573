import React from 'react'
import { Link, useParams } from 'react-router-dom'
import Button from './Button'

const AppSettings = ({ teacher }) => {
    const admin = useParams()
    return (
        <>
            { admin &&
                <>
                    <h3>App Settings</h3>

                    <Link to='/' className='text-link'>
                        <Button title='Main Menu' />
                    </Link>

                    <Link to='/Admin' className='text-link'>
                        <Button title='Admin' />
                    </Link>

                    <h4>Administrators</h4>
                    
                    <h4>Term Rollover</h4>
                </>
            }
        </>
    )
}

export default AppSettings