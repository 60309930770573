import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Link } from 'react-router-dom'
import { getNextWeek, getFURoomStudents } from '../LocalAPICalls'
import { format } from 'date-fns'
import Button from './Button'
import FURoomListItem from './FURoomListItem'

const FURoom = ({ instance, accounts, loginRequest }) => {
	const [date, setDate] = useState({})
	const [dates, setDates] = useState([])
	const [fuRoomStudents, setFuRoomStudents] = useState([])

	useEffect(() => {
		getNextWeek().then((response) => {
			setDates(response)

			// Get the date of next FURoom
			let nextDay = response[5]
			let today = format(new Date(), "yyyy-MM-dd")

			let count = 0

			while(today >= response[count].value) {
				nextDay = response[count++]
			}

			setDate(nextDay)

			getFURoomStudents(nextDay.value).then((response) => {
				setFuRoomStudents(response)
			})
		})
	}, [])

	const upDateDate = (e) => {
		setDate(e)

		getFURoomStudents(e.value).then((response) => {
			setFuRoomStudents(response)
		})
	}

	return (
		<>
			<h3>Follow-up Room</h3>
			<Link to='/' className='text-link'>
				<Button title='Main Menu' />
			</Link>
			<Link to='/Admin' className='text-link'>
				<Button title='Admin' />
			</Link>
			<div className='form-control'>
				<label>Follow-up room dates:</label>
				<Select
					placeholder='Upcoming dates...'
					value={date}
					options={dates}
					onChange={upDateDate}
				/>
			</div>
			<div>
				{ fuRoomStudents.length > 0 &&
					<h3>{fuRoomStudents.length} students in<br />Follow-up room today</h3>
				}

				{!!fuRoomStudents.length ? (
					fuRoomStudents.map((student) => (
						<FURoomListItem
							key={student.id}
							id={student.id}
							student={student.student}
							instance={instance}
							accounts={accounts}
							loginRequest={loginRequest}
						/>
					))
				) : (
					<h4>No students on this day.</h4>
				)}
			</div>
		</>
	)
}

export default FURoom
