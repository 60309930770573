import React from 'react'
import { Link } from 'react-router-dom'
import Button from './Button'
import Search from './Search'

const Commendations = ({instance, accounts, loginRequest, teacher}) => {
  return (
    <>
        <h3>The LORDS Way</h3>
        <Link className='text-link' to='/'><Button title='Main Menu'/></Link>

        <Search
          instance={ instance }
          accounts={ accounts }
          loginRequest={ loginRequest }
          teacher={ teacher }
          menu='C'
        />
    </>
  )
}

export default Commendations