import React, { useState, useEffect } from 'react'

const Image = ({ id, name, size, center }) => {
	const [which, setWhich] = useState(false)

	useEffect(() => {
		try {
			require('./images/profileImages/' + id + '.jpg')
			setWhich(true)
		} catch (err) {
			console.error('Student Register: ', err.message)
		}
	}, [id])

	return (
		<img
			src={
				which
					? require('./images/profileImages/' + id + '.jpg')
					: require('./images/profileImages/dummy.jpg')
			}
			className='student-image'
			width={size}
			alt={name}
			style={{ margin: center ? '5px auto 15px' : '5px 0 15px' }}
			loading='lazy'
		/>
	)
}

export default Image
