import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Button from './Button'
import Select from 'react-select'

const Followup = () => {
    const [option, setOption] = useState({value: 0, label: 'All data'})
    const options = [
        {
            value: 0,
            label: 'All data'
        },
        {
            value: 1,
            label: 'Incomplete'
        },
        {
            value: 2,
            label: 'Roll overs'
        },

    ]

    const generate = () => {

    }

    const handleOptChange = (e) => {
        setOption(e)
    }

    return (
        <>
            <h3>Follow-up Room Data</h3>

            <Link className='text-link' to='/'>
                <Button title='Main Menu' />
            </Link>

            <Link className='text-link' to='/admin'>
                <Button title='Admin' />
            </Link>

            <br />

            <div className='form-control'>
				<label>Register:
          <Select
            placeholder='Choose an option...'
            value={option}
            options={options}
            onChange={handleOptChange}
            autoFocus
          />
        </label>
      </div>

            <Button title='Generate Report' onClick={generate} />
        </>
    )
}

export default Followup