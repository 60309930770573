import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Button from './Button'
import { callMSGraphAll } from '../graph'
import { getThursdayList } from '../LocalAPICalls'
import { utils, writeFileXLSX } from 'xlsx';
import format from 'date-fns/format'

const Thursday = ({ instance, accounts, loginRequest }) => {
  const [studentList, setStudentList] = useState([])

  useEffect(() => {
    const request = {
        ...loginRequest,
        account: accounts[0]
    }
          
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request)
      .then((response) => {
          callMSGraphAll(response.accessToken)
            .then((response) => {
              setStudentList(
                response.value
              )
            })
            return
      })
        .catch((e) => {
            console.log(e.message)
            instance.acquireTokenPopup(request)
              .then((response) => {
                callMSGraphAll(response.accessToken)
                  .then((response) => {
                    setStudentList(
                      response.value
                    )
                  })
                  return
              })
        })
  }, [instance, accounts, loginRequest])

  const writeList = (tList) => {
    const ws = utils.json_to_sheet(tList)
    const wb = utils.book_new()
    utils.book_append_sheet(wb, ws, "Thursday List")
    const today = format(new Date(), 'yyyy-MM-dd')
    writeFileXLSX(wb, `Thursday List as at ${today}.xlsx`)
  }

  const generate = async() => {
    let thursdayList = await getThursdayList()

    thursdayList = thursdayList.map((student) => {
      const thisStudent = studentList.find((el) => el.userPrincipalName === `${student.student}@sol.qld.edu.au`)
      return {
        ...student,
        thisStudent
      }
    })

    thursdayList = thursdayList.map((student) => {
        return ({
          id: student.student,
          name: `${student.givenName} ${student.surname}`,
          pc: student.officeLocation,
          date: format(new Date(student.date), 'dd/MM/yyyy'),
          reason: student.reason,
          teacher: student.teacher,
          notes: student.notes,
          superNotes: student.superNotes
      })
    })

    writeList(thursdayList)
  }

  return (
    <>
      <h3>Thursday List</h3>

      <Link className='text-link' to='/'>
        <Button title='Main Menu' />
      </Link>

      <Link className='text-link' to='/admin'>
        <Button title='Admin' />
      </Link>

      <Link className='text-link' to='/reports'>
        <Button title='Reports' />
      </Link>

      <br />

      <Button title='Generate Report' onClick={generate} />
    </>
  )
}

export default Thursday