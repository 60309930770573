import React, { useEffect, useState, useRef } from 'react'
import { getFURoomItem, updateFURoom } from '../LocalAPICalls'
import { callMsGraphStaff } from '../graph'
import Image from './Image'
import Button from './Button'

const FURoomCard = ({ instance, accounts, loginRequest, id, student, studentName, pc, showDetails }) => {
    const [teacherName, setTeacherName] = useState('')
    const [reason, setReason] = useState('')
    const [notes, setNotes] = useState('')
    const [completed, setCompleted] = useState(false)
    const [sNotes, setSNotes] = useState('')

    const radioTrue = useRef()
    const radioFalse = useRef()

    useEffect(() => {
        let searchString = ''

        const request = {
            ...loginRequest,
            account: accounts[0]
        }

        getFURoomItem(id)
            .then((response) => {
                setReason(response[0].reason)
                setNotes(response[0].notes)
                searchString = response[0].teacher
                setCompleted(response[0].completed)
                if(response[0].completed === '1') {
                    radioTrue.current.checked = true
                    radioFalse.current.checked = false
                } else {
                    radioTrue.current.checked = false
                    radioFalse.current.checked = true
                }
                setSNotes(response[0].superNotes)
            })
            .finally(() => {
                // Silently acquires an access token which is then attached to a request for Microsoft Graph data
                instance.acquireTokenSilent(request)
                    .then((response) => {
                        callMsGraphStaff(searchString, response.accessToken)
                            .then((response) => {
                                setTeacherName(response.value[0].displayName)
                            })
                    })
                    .catch((e) => {
                        console.error('Student Register: ', e.message)
                        instance.acquireTokenPopup(request)
                            .then((response) => {
                                callMsGraphStaff(searchString, response.accessToken)
                                    .then((response) => {
                                        setTeacherName(response.value[0].displayName)
                                    })
                            })
                    })
            })
    }, [accounts, instance, loginRequest, id])

    const turnOn = () => {
        setCompleted(true)
    }

    const turnOff = () => {
        setCompleted(false)
    }

    const selectOnly = (e) => {
        e.stopPropagation()
    }

    const textChanged = (e) => {
        setSNotes(e.target.value)
    }

    const submitSupervisor = (e) => {
        e.stopPropagation()
        //const sNotes = document.getElementById('sNotes').value
        updateFURoom(id, completed, sNotes)
            .then(showDetails())
    }

  return (
    <div
        className='student-card vertical'
        onClick={ showDetails }
        style={{ top: '80px' }}
    >
        <div>
            <strong>Teacher: </strong>{ teacherName }<br />
            <strong>Reason: </strong>{ reason }<br />
            { notes && 
                <>
                    <strong>Notes: </strong>{ notes }
                </>
            }

            <div className='the-form'>
                <label>
                    Completed:
                </label>
                <div />
                <label>
                    <input
                        type='radio'
                        name='completed'
                        onClick={ selectOnly }
                        onChange={ turnOn }
                        ref={ radioTrue }
                    /> <span>Yes</span>
                </label>

                <label>
                    <input
                        type='radio'
                        name='completed'
                        onClick={ selectOnly }
                        onChange={ turnOff }
                        ref={ radioFalse }
                    /> <span>No</span>
                </label>
            </div>

            <div className='form-control'>
                <label>Supervisor notes:</label>

                <textarea
                    id='sNotes'
                    placeholder='Type any supervisor notes here...'
                    rows='4'
                    onClick={ selectOnly }
                    onChange={ textChanged }
                    autoFocus
                    value={ sNotes || '' }
                />
            </div>
            <Button title='Submit' onClick={ submitSupervisor } />
        </div>
        <Image
            id={ student }
            name={ studentName }
            size='100px'
            center={ false }
        />
        <h3>{ studentName } ({ pc })</h3>
    </div>
  )
}

export default FURoomCard