import React from 'react'
import Button from './Button'
import { Link } from 'react-router-dom'

const Reports = ({ instance, accounts, loginRequest }) => {
  return (
    <>
      <h3>Reports</h3>

      <Link className='text-link' to='/'>
			  <Button title='Main Menu' />
		  </Link>
      
      <Link className='text-link' to='/admin'>
        <Button title='Admin' />
      </Link>

      <br />

      <Link className='text-link' to='/RegReport'>
        <Button title='Term Register Data' />
      </Link>

      <Link className='text-link' to='/Thursday'>
        <Button title='Thursday List' />
      </Link>

      <Link className='text-link' to='/Followup'>
        <Button title='Follow-up room data' />
      </Link>
    </>
  )
}

export default Reports