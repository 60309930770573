import React from 'react'
import { useMsal } from '@azure/msal-react'
import Button from './Button'

const LogOut = () => {
    const { instance } = useMsal()

    const handleLogout = () => {
        instance.logoutPopup()            
    }

  return (
    <Button
        title='Sign Out'
        onClick={() => handleLogout() }
    />
  )
}

export default LogOut