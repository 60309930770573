import React, { useState } from 'react'
import { callMsGraphStaffSearch } from '../graph'
import AdminDisplay from './AdminDisplay'

const AdminSearch = ({ instance, accounts, loginRequest, admin }) => {
	const [graphData, setGraphData] = useState([])

	function RequestProfileData() {
		const searchString = document.getElementById('searchString').value
		if (searchString && searchString.length >= 1) {
			const request = {
				...loginRequest,
				account: accounts[0],
			}

			// Silently acquires an access token which is then attached to a request for Microsoft Graph data
			instance
				.acquireTokenSilent(request)
				.then((response) => {
					callMsGraphStaffSearch(searchString, response.accessToken).then(
						(response) => {
							setGraphData(response)
							console.log(response.value)
						}
					)
				})
				.catch((e) => {
					instance.acquireTokenPopup(request).then((response) => {
						callMsGraphStaffSearch(searchString, response.accessToken).then(
							(response) => {
								setGraphData(response)
								console.log(response.value)
							}
						)
					})
				})
		}
	}

	return (
		<div className='form-control'>
			<label>Teacher search:</label>
			<input
				id='searchString'
				placeholder='Type in a teacher&#039;s name...'
				onKeyUp={() => RequestProfileData()}
			/>
			{graphData.value && <AdminDisplay graphData={graphData} />}
		</div>
	)
}

export default AdminSearch
