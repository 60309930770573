import React, { useEffect, useState } from 'react'
import Image from './Image'
import { getTicketColor } from '../LocalAPICalls'
import { callMsGraphByID } from '../graph'
import { Link } from 'react-router-dom'

const StudentLeaderBoardItem = ({ instance, accounts, loginRequest, student, which, count, place }) => {
  const [studentName, setStudentName] = useState('');
  const [pc, setPC] = useState('');

  useEffect(() => {
    const getStudentDetails = () => {
      const searchString = student
      
      const request = {
          ...loginRequest,
          account: accounts[0]
      }
      
      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      instance.acquireTokenSilent(request)
          .then((response) => {
              callMsGraphByID(searchString, response.accessToken)
                  .then((response) => {
                      setStudentName(response.value[0].displayName)
                      setPC(response.value[0].officeLocation)
                  })
          })
          .catch((e) => {
              console.log(e.message)
              instance.acquireTokenPopup(request)
                  .then((response) => {
                      callMsGraphByID(searchString, response.accessToken)
                          .then((response) => {
                              setStudentName(response.value[0].displayName)
                              setPC(response.value[0].officeLocation)
                          })
                  })
          })
        }
  getStudentDetails()
  }, [accounts, loginRequest, instance, student])

  const getLabel = () => {
    let label = ''

    if(which === 'T') {
        label = 'track'
    } if(which === 'C') {
        label = 'commendation'
    } else {
        label = 'ticket'
    }

    if(count > 1) {
        label += 's'
    }

    return label
  }

  return (
    <Link
        className='student-list-link text-link'
        to={ '/Student/' +
                student +
                '/' + studentName +
                '/' + pc +
                '/' + which +
                '/none/pct'
        }
    >
    <div
        className='student-panel'
        style={{
            borderLeft: 'solid 6px ' + getTicketColor(count)
        }}
    >
        <div className='start-of-card'>
            <div className='place'>{ place }</div>
            <div>{ studentName } ({ pc })<br />{ count } { getLabel() }</div>
        </div>
        <Image
            id={ student }
            name={ studentName }
            size='40px'
            center={ false }
        />
    </div>
    </Link>
  )
}

export default StudentLeaderBoardItem