import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { PageLayout } from './components/PageLayout'
import {
	useMsalAuthentication,
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
	useMsal,
} from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { loginRequest } from './authConfig'
import Menu from './components/Menu'
import Tracker from './components/Tracker'
import Behaviour from './components/Behaviour'
import Academic from './components/Academic'
import Student from './components/Student'
import Teacher from './components/Teacher'
import Uniform from './components/Uniform'
import Admin from './components/Admin'
import Feedback from './components/Feedback'
import Commendations from './components/Commendations'
import AppSettings from './components/AppSettings'
import StudentLeaderboard from './components/StudentLeaderboard'
import StaffLeaderboard from './components/StaffLeaderboard'
import MyClasses from './components/MyClasses'
import MyTickets from './components/MyTickets'
import FURoom from './components/FURoom'
import Reports from './components/Reports'
import Thursday from './components/Thursday'
import RegReport from './components/RegReport'
import Stack from './components/Stack'
import Test from './components/Test'
import My404 from './components/My404'
import Spinner from './components/Spinner'
import Followup from './components/Followup'
import { getTerm, isAdmin } from './LocalAPICalls'

const ProfileContent = ({ term }) => {
	const { instance, accounts } = useMsal()
	const [admin, setAdmin] = useState(false)

	const name = accounts[0] && accounts[0].name
	const teacher = accounts[0] && accounts[0].username.split('@')[0].toLowerCase()

	isAdmin(teacher)
		.then((response) => {
			setAdmin(response[0].isAdmin === '1')
		})
		.catch((err) => {
			console.log(err.message)
		})

	return (
		<>
			<div className='nav'>
				<p>Signed in as {name}</p>
			</div>

			<Routes>
				<Route path='/' exact={true} element={<Menu />} />
				<Route
					path='Tracker'
					element={
						<Tracker
							instance={instance}
							accounts={accounts}
							loginRequest={loginRequest}
							teacher={teacher}
							admin={admin}
						/>
					}
				/>
				<Route
					path='Uniform'
					element={
						<Uniform
							instance={instance}
							accounts={accounts}
							loginRequest={loginRequest}
							teacher={teacher}
							admin={admin}
						/>
					}
				/>
				<Route
					path='Behaviour'
					element={
						<Behaviour
							instance={instance}
							accounts={accounts}
							loginRequest={loginRequest}
							teacher={teacher}
							admin={admin}
						/>
					}
				/>
				<Route
					path='Academic'
					element={
						<Academic
							instance={instance}
							accounts={accounts}
							loginRequest={loginRequest}
							teacher={teacher}
							admin={admin}
						/>
					}
				/>

				<Route
					path='Commendations'
					element={
						<Commendations
							instance={instance}
							accounts={accounts}
							loginRequest={loginRequest}
							teacher={teacher}
							admin={admin}
						/>
					}
				/>

				<Route
					path='Admin'
					element={
						<Admin
							instance={instance}
							accounts={accounts}
							loginRequest={loginRequest}
							teacher={teacher}
							admin={admin}
						/>
					}
				/>
				<Route path='AppSettings' element={<AppSettings />} />
				<Route
					path='StudentLeaderboard'
					element={
						<StudentLeaderboard
							instance={instance}
							accounts={accounts}
							loginRequest={loginRequest}
							admin={admin}
						/>
					}
				/>
				<Route
					path='StaffLeaderboard'
					element={
						<StaffLeaderboard
							instance={instance}
							accounts={accounts}
							loginRequest={loginRequest}
							admin={admin}
						/>
					}
				/>
				<Route
					path='MyClasses/:myClass'
					element={
						<MyClasses
							instance={instance}
							accounts={accounts}
							loginRequest={loginRequest}
							teacher={teacher}
							admin={admin}
						/>
					}
				/>
				<Route
					path='MyTickets'
					element={
						<MyTickets
							instance={instance}
							accounts={accounts}
							loginRequest={loginRequest}
							teacher={teacher}
							admin={admin}
						/>
					}
				/>
				<Route
					path='FURoom'
					element={
						<FURoom
							instance={instance}
							accounts={accounts}
							loginRequest={loginRequest}
						/>
					}
				/>
				<Route
					path='Reports'
					element={
						<Reports
							instance={instance}
							accounts={accounts}
							loginRequest={loginRequest}
						/>
					}
				/>
				<Route
					path='Student/:student/:name/:pc/:menu/:theClass/:pct'
					element={
						<Student
							instance={instance}
							accounts={accounts}
							loginRequest={loginRequest}
							teacher={teacher}
							tName={name}
							admin={admin}
							term={term}
						/>
					}
				/>
				<Route
					path='Teacher/:tID/:name/:menu'
					element={
						<Teacher
							instance={instance}
							accounts={accounts}
							loginRequest={loginRequest}
							teacher={teacher}
							admin={admin}
						/>
					}
				/>
				<Route
					path='Feedback'
					element={
						<Feedback
							who={name}
						/>
					}
				/>

				<Route
					path='Thursday'
					element={
						<Thursday
							instance={instance}
							accounts={accounts}
							loginRequest={loginRequest}
						/>
					}
				/>

				<Route
					path='RegReport'
					element={
						<RegReport
							instance={instance}
							accounts={accounts}
							loginRequest={loginRequest}
						/>
					}
				/>

				<Route
					path='Followup'
					element={
						<Followup
							instance={instance}
							accounts={accounts}
							loginRequest={loginRequest}
						/>
					}
				/>

				<Route
					path='Test'
					element={
						<Test
							instance={instance}
							accounts={accounts}
							loginRequest={loginRequest}
						/>
					}
				/>

				<Route
					path='Spinner'
					element={
						<Spinner />
					}
				/>
				<Route
					path='Stack'
					element={
						<Stack
							instance={instance}
							accounts={accounts}
							loginRequest={loginRequest}
						/>
					}
				/>

				<Route path='*' exact={true} element={<My404 />} />
			</Routes>
		</>
	)
}

const App = () => {
	const [term, setTerm] = useState(0)

	useEffect(() => {
		getTerm()
			.then((res) => {
				setTerm(res[0].term)
			})
	}, [term])

	require('dotenv').config()
	useMsalAuthentication(InteractionType.Redirect)

	const version = process.env.REACT_APP_VERSION
	const isDev = process.env.REACT_APP_MODE

	return (
		<Router>
			<PageLayout version={version} isDev={isDev}>
				<AuthenticatedTemplate>
					<ProfileContent term={term} />
				</AuthenticatedTemplate>
				<UnauthenticatedTemplate>
					<center>
						<p>You are not signed in!</p>
						<p>Refresh your browser to sign in</p>
					</center>
				</UnauthenticatedTemplate>
			</PageLayout>
		</Router>
	)
}

export default App
