import React from 'react'
import Header from './Header'
import Footer from './Footer'
import LogOut from './LogOut'

export const PageLayout = (props) => {

    return (
        <div className='container'>
            <Header />
            {props.children}
            <Footer version={props.version} isDev={props.isDev} />
            <LogOut />
        </div>
    )
}